import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LazyImage from "react-lazy-blur-image";
import InfiniteScroll from "react-infinite-scroll-component";
import { PiCurrencyInr } from "react-icons/pi";
import { MdFavoriteBorder } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import {
  handleCartMinus,
  handleCartPlus,
  handleFetchCategoryData,
  handleFetchColorCodesData,
  handleFetchProductsColorCodesData,
  handleFetchProductsData,
  handleFetchProductsDataSliced,
  handleFetchProductsSizeData,
  handleFetchSizeData,
  handleFetchVersionManagerData,
  handleGetCartInfoStorageItems,
} from "../utilities/cartManager";
import "./customstyle.css";
import { getUserdata } from "../utilities/sessionexpiry";
import {
  handleGetCategoryInfoStorageItems,
  handleGetColorCodeStorageItems,
  handleGetProductsColorCodesStorageItems,
  handleGetProductsInfoStorageItems,
  handleGetProductsSizeStorageItems,
  handleGetSizeStorageItems,
  handleGetVersionManagerStorageItems,
  handleSetCategoryInfoStorageItems,
  handleSetVersionManagerStorageItems,
} from "../utilities/storageManager";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import MultiRangeSlider from "multi-range-slider-react";
import { useNavigate, useParams } from "react-router-dom";
import { AK } from "../constants/AppKeys";
import { callStores } from "../utilities/storeManager";
import { checkerArray, checkerString } from "../utilities/checker";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { lowercasenosp } from "../utilities/checker";
const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function TermsAndCondtions(props) {
  const {
    store,
    description,
    assets,
    storeid,
    hdimage,
    productimage,
    productviewimage,
    thumbnailviewimage,
    assetsUrl,
    categoryListData,
    userData,
    versionmanagerListData,
    colorcodesListData,
    productscolorcodesListData,
    sizeListData,
    productssizeListData,
    productsListData,
    cartinfoData,
    setCartInfoData,
    count,
    setCount,
    pageRefresh,
    footercopyrighttext,
    showFooter,
    setshowFooter,
  } = props;

  const [pageinit, setPageInit] = useState(false);
  const [showFormLoader, setFormLoader] = useState(false);
  const [producthasMore, setProductHasMore] = useState(true);
  const params = useParams();
  const [productsListPageData, setProductsListPageData] = useState([]);
  const [productsListDisplayData, setProductsListDisplayData] = useState([]);
  const [sizeListDisplayData, setSizeListDisplayData] = useState([]);
  const [colorListDisplayData, setColorListDisplayData] = useState([]);
  const [productsListDisplayDataCount, setProductsListDisplayDataCount] =
    useState(20);
  const [
    productsListIncreaseDisplayDataCount,
    setProductsListIncreaseDisplayDataCount,
  ] = useState(20);
  const [mobileFilterTab, setmobileFilterTab] = useState("sizes");

  const [availableData, setAvailabeData] = useState({
    colorcodes: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
    size: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
    products: [
      { id: 110 },
      { id: 111 },
      { id: 112 },
      { id: 113 },
      { id: 114 },
      { id: 115 },
      { id: 116 },
      { id: 117 },
      { id: 118 },
      { id: 119 },
      { id: 120 },
      { id: 121 },
      { id: 122 },
      { id: 123 },
      { id: 124 },
      { id: 125 },
      { id: 126 },
      { id: 127 },
      { id: 128 },
      { id: 129 },
    ],
  });
  const [rangeData, setRangeData] = useState({
    min: 5,
    max: 10,
  });
  const [rangeData1, setRangeData1] = useState(0);
  const [incr, setIncr] = useState(0);
  const [minValue, set_minValue] = useState(300);
  const [maxValue, set_maxValue] = useState(600);
  const handleInputSlider = async (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
    if (e.minValue != minValue) await filterProductByCategoryChecks();
    if (e.maxValue != maxValue) await filterProductByCategoryChecks();
  };
  const navigate = useNavigate();

  useEffect(() => {
    console.log("***");
  }, [cartinfoData.cartcount, cartinfoData, cartinfoData.products]);

  useEffect(() => {
    console.log("***");
  }, [count]);

  const getcartCount = () => {
    return cartinfoData.cartcount;
  };
  if (!pageinit) {
    setPageInit(true);
    pageRefresh();
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className="relative">
          <nav
            style={{ fontWeight: 500, fontSize: "13px" }}
            className="flex justify-between border-b navcustombg"
          >
            <div className="px-6 py-2 flex w-full items-center">
              {/* <a className="hidden xl:block mr-16" href='javascript:;'>
                <svg
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18 15.4688H0V17.7207H18V15.4688Z" fill="black" />
                  <path
                    d="M11.0226 7.87402H0V10.126H11.0226V7.87402Z"
                    fill="black"
                  />
                  <path d="M18 0.279297H0V2.53127H18V0.279297Z" fill="black" />
                </svg>
              </a> */}
              <ul className="hidden xl:flex font-heading">
                <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products/men">
                    MEN
                  </a>
                </li>
                <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products/women">
                    WOMEN
                  </a>
                </li>
                <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products/kids">
                    KIDS
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    className="hover:text-gray-600"
                    href="/products/couplecollection"
                  >
                    COUPLE COLLECTION
                  </a>
                </li>
                {/* <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products">
                    Apparels
                  </a>
                </li> */}
                {/* <li>
                  <a
                    className="hover:text-gray-600"
                    href="/products/politicalshirts"
                  >
                    POLITICAL SHIRTS
                  </a>
                </li> */}
                <li>
                  <a className="hover:text-gray-600" href="/products/apparels">
                    APPARELS
                  </a>
                </li>
              </ul>
              <a
                className="flex-shrink-0 xl:mx-auto text-3xl font-bold font-heading"
                href="/"
              >
                <img
                  style={{ height: "38px" }}
                  className="h-12"
                  src={`/yofte-assets/logos/${lowercasenosp(store)}/logo.webp`}
                  alt=""
                  width="auto"
                />
              </a>
              {/* <div className="hidden xl:inline-block mr-14">
                <input
                  className="py-5 px-8 w-full placeholder-gray-400 text-xs uppercase font-semibold font-heading bg-gray-50 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                  type="text"
                  placeholder="Search"
                />
              </div> */}

              <div className="hidden xl:flex items-center">
                {/* <div className="pull-right mainHeaderCols activemenuwrp">
                  <div className="actionMenu" style={{ padding: "15px" }}>
                    <span
                      className="actionMenu actionMenuInner"
                      id="testHeaderCart"
                    >
                      <a
                        href="/cart"
                        className="cartIcon"
                        style={{ paddingRight: 16, position: "relative" }}
                      >
                        <i className="icon_bag" aria-hidden="true" />
                        <span className="cartCount"> {getcartCount()}</span>
                      </a>
                    </span>
                    <span
                      className="actionMenu"
                      id="testHeadWish"
                      style={{ padding: "0px 5px", cursor: "pointer" }}
                    >
                      <div>
                        <div className="">
                          <i
                            className="icon_wishlist"
                            style={{ fontSize: "20px" }}
                          />
                        </div>
                      </div>
                    </span>
                    <a href="#" id="testHeaderAcc">
                      <i className="icon_user" />
                    </a>
                  </div>
                </div> */}

                <a className="mr-2 hover:text-gray-600" href="#">
                  <svg
                    width={23}
                    height={20}
                    viewBox="0 0 23 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4998 19.2061L2.70115 9.92527C1.92859 9.14433 1.41864 8.1374 1.24355 7.04712C1.06847 5.95684 1.23713 4.8385 1.72563 3.85053V3.85053C2.09464 3.10462 2.63366 2.45803 3.29828 1.96406C3.9629 1.47008 4.73408 1.14284 5.5483 1.00931C6.36252 0.875782 7.19647 0.939779 7.98144 1.19603C8.7664 1.45228 9.47991 1.89345 10.0632 2.48319L11.4998 3.93577L12.9364 2.48319C13.5197 1.89345 14.2332 1.45228 15.0182 1.19603C15.8031 0.939779 16.6371 0.875782 17.4513 1.00931C18.2655 1.14284 19.0367 1.47008 19.7013 1.96406C20.3659 2.45803 20.905 3.10462 21.274 3.85053V3.85053C21.7625 4.8385 21.9311 5.95684 21.756 7.04712C21.581 8.1374 21.071 9.14433 20.2984 9.92527L11.4998 19.2061Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <a
                  className="flex items-center hover:text-gray-600"
                  href="/cart"
                >
                  {/* <svg
                    className="mr-3"
                    width={23}
                    height={23}
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1159 8.72461H2.50427C1.99709 8.72461 1.58594 9.12704 1.58594 9.62346V21.3085C1.58594 21.8049 1.99709 22.2074 2.50427 22.2074H18.1159C18.6231 22.2074 19.0342 21.8049 19.0342 21.3085V9.62346C19.0342 9.12704 18.6231 8.72461 18.1159 8.72461Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.34473 6.34469V4.95676C6.34473 3.85246 6.76252 2.79338 7.5062 2.01252C8.24988 1.23165 9.25852 0.792969 10.3102 0.792969C11.362 0.792969 12.3706 1.23165 13.1143 2.01252C13.858 2.79338 14.2758 3.85246 14.2758 4.95676V6.34469"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span
                    style={{ padding: "3px" }}
                    className="inline-block w-6 h-6 text-center text-white bg-orange-300  hover:bg-orange-400 rounded-full font-heading"
                  >
                    {getcartCount()}
                  </span> */}
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/cart">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="iconoir:cart">
                        <g id="Group">
                          <path
                            id="Vector"
                            d="M19.5 22.2592C19.8978 22.2592 20.2794 22.1006 20.5607 21.8183C20.842 21.5359 21 21.153 21 20.7537C21 20.3544 20.842 19.9715 20.5607 19.6891C20.2794 19.4068 19.8978 19.2481 19.5 19.2481C19.1022 19.2481 18.7206 19.4068 18.4393 19.6891C18.158 19.9715 18 20.3544 18 20.7537C18 21.153 18.158 21.5359 18.4393 21.8183C18.7206 22.1006 19.1022 22.2592 19.5 22.2592ZM9.5 22.2592C9.89782 22.2592 10.2794 22.1006 10.5607 21.8183C10.842 21.5359 11 21.153 11 20.7537C11 20.3544 10.842 19.9715 10.5607 19.6891C10.2794 19.4068 9.89782 19.2481 9.5 19.2481C9.10218 19.2481 8.72064 19.4068 8.43934 19.6891C8.15804 19.9715 8 20.3544 8 20.7537C8 21.153 8.15804 21.5359 8.43934 21.8183C8.72064 22.1006 9.10218 22.2592 9.5 22.2592Z"
                            fill="#000000"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M5 4.19259H22L20 15.2333M5 4.19259L7 15.2333H20M5 4.19259C4.833 3.52312 4 2.18518 2 2.18518M20 15.2333H5.23C3.446 15.2333 2.5 16.0172 2.5 17.2407C2.5 18.4642 3.446 19.2481 5.23 19.2481H19.5"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </g>
                    </svg>
                  </a> */}
                  <div className="pull-right mainHeaderCols activemenuwrp">
                    <div className="actionMenu" style={{ padding: "10px" }}>
                      <span
                        className="actionMenu actionMenuInner"
                        id="testHeaderCart"
                      >
                        <a
                          href="/cart"
                          className="cartIcon"
                          style={{ paddingRight: 16, position: "relative" }}
                        >
                          {/* <i className="icon_bag" aria-hidden="true" /> */}
                          <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                          <span className="cartCount"> {getcartCount()}</span>
                        </a>
                      </span>

                      {/* <span
                        className="actionMenu actionMenuInner"
                        id="testHeaderCart"
                      >
                        <a
                          href="/cart"
                          className="cartIcon"
                          style={{ paddingRight: 16, position: "relative" }}
                        ></a>
                      </span> */}
                      {/* <span className="cartCount"> {getcartCount()}</span> */}

                      {/* <span className="cartCount"> {getcartCount()}</span> */}

                      {/* <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="iconoir:cart">
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M19.5 22.2592C19.8978 22.2592 20.2794 22.1006 20.5607 21.8183C20.842 21.5359 21 21.153 21 20.7537C21 20.3544 20.842 19.9715 20.5607 19.6891C20.2794 19.4068 19.8978 19.2481 19.5 19.2481C19.1022 19.2481 18.7206 19.4068 18.4393 19.6891C18.158 19.9715 18 20.3544 18 20.7537C18 21.153 18.158 21.5359 18.4393 21.8183C18.7206 22.1006 19.1022 22.2592 19.5 22.2592ZM9.5 22.2592C9.89782 22.2592 10.2794 22.1006 10.5607 21.8183C10.842 21.5359 11 21.153 11 20.7537C11 20.3544 10.842 19.9715 10.5607 19.6891C10.2794 19.4068 9.89782 19.2481 9.5 19.2481C9.10218 19.2481 8.72064 19.4068 8.43934 19.6891C8.15804 19.9715 8 20.3544 8 20.7537C8 21.153 8.15804 21.5359 8.43934 21.8183C8.72064 22.1006 9.10218 22.2592 9.5 22.2592Z"
                              fill="#000000"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              id="Vector_2"
                              d="M5 4.19259H22L20 15.2333M5 4.19259L7 15.2333H20M5 4.19259C4.833 3.52312 4 2.18518 2 2.18518M20 15.2333H5.23C3.446 15.2333 2.5 16.0172 2.5 17.2407C2.5 18.4642 3.446 19.2481 5.23 19.2481H19.5"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </g>
                      </svg> */}

                      {/* <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="iconoir:cart">
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M19.5 22.2592C19.8978 22.2592 20.2794 22.1006 20.5607 21.8183C20.842 21.5359 21 21.153 21 20.7537C21 20.3544 20.842 19.9715 20.5607 19.6891C20.2794 19.4068 19.8978 19.2481 19.5 19.2481C19.1022 19.2481 18.7206 19.4068 18.4393 19.6891C18.158 19.9715 18 20.3544 18 20.7537C18 21.153 18.158 21.5359 18.4393 21.8183C18.7206 22.1006 19.1022 22.2592 19.5 22.2592ZM9.5 22.2592C9.89782 22.2592 10.2794 22.1006 10.5607 21.8183C10.842 21.5359 11 21.153 11 20.7537C11 20.3544 10.842 19.9715 10.5607 19.6891C10.2794 19.4068 9.89782 19.2481 9.5 19.2481C9.10218 19.2481 8.72064 19.4068 8.43934 19.6891C8.15804 19.9715 8 20.3544 8 20.7537C8 21.153 8.15804 21.5359 8.43934 21.8183C8.72064 22.1006 9.10218 22.2592 9.5 22.2592Z"
                              fill="#000000"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              id="Vector_2"
                              d="M5 4.19259H22L20 15.2333M5 4.19259L7 15.2333H20M5 4.19259C4.833 3.52312 4 2.18518 2 2.18518M20 15.2333H5.23C3.446 15.2333 2.5 16.0172 2.5 17.2407C2.5 18.4642 3.446 19.2481 5.23 19.2481H19.5"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </g>
                      </svg> */}

                      {/* <a
                        href="/cart"
                        className="cartIcon"
                        style={{ paddingRight: 16, position: "relative" }}
                      >
                        <i className="icon_bag" aria-hidden="true" />
                        <span className="cartCount"> {getcartCount()}</span>
                      </a> */}
                      {/* <span
                        className="actionMenu"
                        id="testHeadWish"
                        style={{ padding: "0px 5px", cursor: "pointer" }}
                      >
                        <div>
                          <div className="">
                            <i
                              className="icon_wishlist"
                              style={{ fontSize: "20px" }}
                            />
                          </div>
                        </div>
                      </span>
                      <a href="#" id="testHeaderAcc">
                        <i className="icon_user" />
                      </a> */}
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="hidden xl:flex items-center px-12 border-l font-heading hover:text-gray-600">
              <a
                href="/signin"
                style={{
                  display: userData != null ? "none" : "flex",
                }}
              >
                <svg
                  className="mr-3"
                  width={32}
                  height={31}
                  viewBox="0 0 32 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span style={{ width: "150px", paddingTop: "5px" }}>
                  SIGN&nbsp;IN
                </span>
              </a>

              <a
                className="dropdown"
                href={() => {
                  return false;
                }}
                style={{
                  display: userData === null ? "none" : "flex",
                  cursor: "pointer",
                }}
              >
                <svg
                  className="mr-3"
                  width={32}
                  height={31}
                  viewBox="0 0 32 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span style={{ width: "150px", paddingTop: "5px" }}>
                  {userData?.name}
                </span>
                <div className="dropdown-content">
                  <a href="/myprofile">My Profile</a>
                  <a href="/orderhistory">My Orders</a>
                  <a href="/changepassword">Change Password</a>
                  <hr
                    style={{
                      border: "1px solid #CFD5E2",
                      height: "2px",
                    }}
                  />
                  <a href="/signin">Logout</a>
                </div>
              </a>
            </div>
            {/* <div className="pull-right mainHeaderCols activemenuwrp">
              <div className="actionMenu" style={{ padding: "10px" }}>
                <span
                  className="actionMenu actionMenuInner"
                  id="testHeaderCart"
                >
                  <a
                    href="/cart"
                    className="cartIcon"
                    style={{ paddingRight: 16, position: "relative" }}
                  >
                    <i className="icon_bag" aria-hidden="true" />
                    <span className="cartCount"> {getcartCount()}</span>
                  </a>
                </span>
              </div>
            </div> */}

            <a
              className="xl:hidden flex mr-6 items-center text-gray-900"
              href="/cart"
            >
              {/* <svg
                className="mr-2"
                width={23}
                height={23}
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.1159 8.72461H2.50427C1.99709 8.72461 1.58594 9.12704 1.58594 9.62346V21.3085C1.58594 21.8049 1.99709 22.2074 2.50427 22.2074H18.1159C18.6231 22.2074 19.0342 21.8049 19.0342 21.3085V9.62346C19.0342 9.12704 18.6231 8.72461 18.1159 8.72461Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.34473 6.34469V4.95676C6.34473 3.85246 6.76252 2.79338 7.5062 2.01252C8.24988 1.23165 9.25852 0.792969 10.3102 0.792969C11.362 0.792969 12.3706 1.23165 13.1143 2.01252C13.858 2.79338 14.2758 3.85246 14.2758 4.95676V6.34469"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span
                style={{ padding: "3px" }}
                className="inline-block w-6 h-6 text-center text-white bg-orange-300  hover:bg-orange-400 rounded-full font-heading"
              >
                {getcartCount()}
              </span> */}
            </a>
            <a
              className="navbar-burger self-center mr-12 xl:hidden"
              href={() => {
                return false;
              }}
              onClick={() => {
                document.getElementById("mySidenav").style.width = "290px";
              }}
            >
              <svg
                width={20}
                height={12}
                viewBox="0 0 20 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 2H19C19.2652 2 19.5196 1.89464 19.7071 1.70711C19.8946 1.51957 20 1.26522 20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM19 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H19C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10ZM19 5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H19C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5Z"
                  fill="#8594A5"
                />
              </svg>
            </a>
            <div id="mySidenav" className="sidenav mr-12 xl:hidden">
              <a
                href={() => {
                  return false;
                }}
                className="closebtn"
                onClick={() => {
                  document.getElementById("mySidenav").style.width = "0";
                }}
              >
                &times;
              </a>
              <div className="xl:flex items-center border-l font-heading hover:text-gray-600">
                <a
                  href="/signin"
                  style={{
                    display: userData != null ? "none" : "flex",
                  }}
                >
                  <svg
                    className="mr-3"
                    width={32}
                    height={31}
                    viewBox="0 0 32 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span style={{ width: "150px", paddingTop: "5px" }}>
                    SIGN&nbsp;IN
                  </span>
                </a>

                <a
                  className="dropdown"
                  href="#"
                  style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    className="mr-3"
                    width={32}
                    height={31}
                    viewBox="0 0 32 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span style={{ width: "150px", paddingTop: "0px" }}>
                    {userData?.name}
                  </span>
                  <div className="dropdown-content-nav">
                    <a href="/myprofile">My Profile</a>
                    <a href="/orderhistory">My Orders</a>
                    <a href="/changepassword">Change Password</a>
                    <hr
                      style={{
                        border: "1px solid #CFD5E2",
                        height: "2px",
                      }}
                    />
                    <a href="/signin">Logout</a>
                  </div>
                </a>
              </div>
              <hr
                style={{
                  border: "1px solid #CFD5E2",
                  height: "2px",
                }}
              />
              <ul className="xl:flex" style={{ fontSize: "6px" }}>
                <li className="mr-12 ">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/men"
                  >
                    🕺 &nbsp;&nbsp; Men
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/women"
                  >
                    💃 &nbsp;&nbsp; Women
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/kids"
                  >
                    👶 &nbsp;&nbsp; Kids
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/couplecollection"
                  >
                    👩‍❤️‍👨 &nbsp;&nbsp; Couple Collection
                  </a>
                </li>
                {/* <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products">
                    Apparels
                  </a>
                </li> */}
                <li>
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/Apparels"
                  >
                    🚩 &nbsp;&nbsp; APPARELS
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </section>

        <section className="py-4 testing-1 bg-white container-y">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap -mx-3 mb-2">
              <div>
                <>
                  <br />
                  <>
                    <h3>Terms and Condition</h3>
                    <br />
                    <b>
                      Please read the following terms and conditions very
                      carefully as your use of service is subject to your
                      acceptance of and compliance with the following terms and
                      conditions ("Terms").
                    </b>
                    <br />
                    <br />
                    <p>
                      By subscribing to or using any of our services you agree
                      that you have read, understood and are bound by the Terms,
                      regardless of how you subscribe to or use the services. If
                      you do not want to be bound by the Terms, you must not
                      subscribe to or use our services.
                    </p>
                    <br />
                    <p>
                      In these Terms, references to "you", "User" shall mean the
                      end user accessing the Website, its contents and using the
                      Services offered through the Website, "Service Providers"
                      mean independent third party service providers, and "we",
                      "us" and "our" shall mean VilvaNetworks and its
                      affiliates.
                    </p>
                    <br />
                    <b>Introduction:</b>
                    <br />
                    <p>
                      a) www.tamiltshirts.in website ("Website") is an Internet
                      based content and e-commerce portal owned and operated by
                      VilvaNetworks, a firm registered under the laws of India.
                    </p>
                    <p>
                      b) Use of the Website is offered to you conditioned on
                      acceptance without modification of all the terms,
                      conditions and notices contained in these Terms, as may be
                      posted on the Website from time to time. VilvaNetworks at
                      its sole discretion reserves the right not to accept a
                      User from registering on the Website without assigning any
                      reason thereof.
                    </p>
                    <br />
                    <b>User Account, Password, and Security:</b>
                    <br />
                    <p>
                      You upon completing the Website's registration process
                      through your facebook account or through our native
                      registration process, are responsible for maintaining the
                      confidentiality of the password and account, and are fully
                      responsible for all activities that occur under your
                      password or account. You agree to (a)immediately notify us
                      of any unauthorized use of your password or account or any
                      other breach of security, and (b) ensure that you access
                      your account at your secure environment. Vilvaclothing.in
                      cannot and will not be liable for any loss or damage
                      arising from your failure to comply with this.
                    </p>
                    <br />
                    <b>Services Offered:</b>
                    <p>
                      VilvaNetwork provides a number of Internet-based services
                      through the Web Site (all such services, collectively, the
                      "Service"). One such service enables users to purchase
                      original merchandise such as clothing, footwear and
                      accessories from various fashion and lifestyle brands.
                      (collectively, "Products"). Upon placing order,
                      AdyarHandiCrafts shall ship the product to you and be
                      entitled to its payment for the Services.
                    </p>
                    <br />
                    <b>Intellectual Property Rights:</b>
                    <p>
                      a) Unless otherwise indicated or anything contained to the
                      contrary or any proprietary material owned by a third
                      party and so expressly mentioned, VilvaNetworks owns all
                      Intellectual Property Rights to and into the Website,
                      including, without limitation, any and all rights, title
                      and interest in and to copyright, related rights, patents,
                      utility models, trademarks, trade names, service marks,
                      designs, know-how, trade secrets and inventions (whether
                      patentable or not), goodwill, source code, meta tags,
                      databases, text, content, graphics, icons, and hyperlinks.
                      You acknowledge and agree that you shall not use,
                      reproduce or distribute any content from the Website
                      belonging to tamiltshirts.in without obtaining
                      authorization from VilvaNetworks.
                    </p>
                    <p>
                      b) Notwithstanding the foregoing, it is expressly
                      clarified that you will retain ownership and shall solely
                      be responsible for any content that you provide or upload
                      when using any Service, including any text, data,
                      information, images, photographs, music, sound, video or
                      any other material which you may upload, transmit or store
                      when making use of our various Service. However, with
                      regard to the product customization Service (as against
                      other Services like blogs and forums) you expressly agree
                      that by uploading and posting content on to the Website
                      for public viewing and reproduction/use of your content by
                      third party users, you accept the User whereby you grant a
                      non-exclusive license for the use of the same.
                    </p>
                    <br />
                    <b>Links To Third Party Sites:</b>
                    <p>
                      The Website may contain links to other websites ("Linked
                      Sites").The Linked Sites are not under the control of
                      VilvaNetworks or the Website www.tamiltshirts.in is not
                      responsible for the contents of any Linked Site, including
                      without limitation any link contained in a Linked Site, or
                      any changes or updates to a Linked Site. VilvaNetworks is
                      not responsible for any form of transmission, whatsoever,
                      received by you from any Linked Site. tamiltshirts.in is
                      providing these links to you only as a convenience, and
                      the inclusion of any link does not imply endorsement by
                      VilvaNetworks or the Website of the Linked Sites or any
                      association with its operators or owners including the
                      legal heirs or assigns thereof. The users are requested to
                      verify the accuracy of all information on their own before
                      undertaking any reliance on such information.
                    </p>
                    <br />
                    <b>Disclaimer Of Warranties/Limitation Of Liability:</b>
                    <p>
                      VilvaNetworks has endeavoured to ensure that all the
                      information on the Website is correct, but neither
                      warrants nor makes any representations regarding the
                      quality, accuracy or completeness of any data,
                      information, product or Service. In no event shall
                      VilvaNetworks be liable for any direct, indirect,
                      punitive, incidental, special, consequential damages or
                      any other damages resulting from: (a) the use or the
                      inability to use the Services; (b) unauthorized access to
                      or alteration of the user's transmissions or data; (c) any
                      other matter relating to the services; including, without
                      limitation, damages for loss of use, data or profits,
                      arising out of or in any way connected with the use or
                      performance of the Website or Service. Neither shall
                      VilvaNetworks be responsible for the delay or inability to
                      use the Website or related services, the provision of or
                      failure to provide Services, or for any information,
                      software, products, services and related graphics obtained
                      through the Website, or otherwise arising out of the use
                      of the website, whether based on contract, tort,
                      negligence, strict liability or otherwise. Further,
                      VilvaNetworks shall not be held responsible for
                      non-availability of the Website during periodic
                      maintenance operations or any unplanned suspension of
                      access to the website that may occur due to technical
                      reasons or for any reason beyond VilvaNetworks control.
                      The user understands and agrees that any material and/or
                      data downloaded or otherwise obtained through the Website
                      is done entirely at their own discretion and risk and they
                      will be solely responsible for any damage to their
                      computer systems or loss of data that results from the
                      download of such material and/or data.
                    </p>
                    <br />
                    <b>Indemnification:</b>
                    <p>
                      You agree to indemnify, defend and hold harmless from and
                      against any and all losses, liabilities, claims, damages,
                      costs and expenses (including legal fees and disbursements
                      in connection therewith and interest chargeable thereon)
                      asserted against or incurred by tamiltshirts.in that arise
                      out of, result from, or may be payable by virtue of, any
                      breach or non-performance of any representation, warranty,
                      covenant or agreement made or obligation to be performed
                      by you pursuant to these Terms.
                    </p>
                    <br />
                    <b>Pricing:</b>
                    <p>
                      Prices for products are described on our Website and are
                      incorporated into these Terms by reference. All prices are
                      in Indian rupees. Prices, products and Services may change
                      at our own discretion.
                    </p>
                    <br />
                    <b>Shipping:</b>
                    <p>
                      Title and risk of loss for all products ordered by you
                      shall pass on to you upon our shipment to the shipping
                      carrier.
                    </p>
                    <br />
                    <b>Termination:</b>
                    <p>
                      a) VilvaNetworks may suspend or terminate your use of the
                      Website or any Service if it believes, in its sole and
                      absolute discretion that you have breached a term of these
                      Terms.
                    </p>
                    <p>
                      b) You shall be liable to pay for any Service or product
                      that you have already ordered till the time of Termination
                      by either party whatsoever. Further, you shall be entitled
                      to your royalty payments as per the User License Agreement
                      that has or is legally deemed accrued to you.
                    </p>
                    <br />
                    <b>Governing Law:</b>
                    <p>
                      These terms shall be governed by and constructed in
                      accordance with the laws of India without reference to
                      conflict of laws principles and disputes arising in
                      relation hereto shall be subject to the exclusive
                      jurisdiction of the courts at Chennai only.
                    </p>
                    <br />
                    <b>Headings:</b>
                    <p>
                      The headings and subheadings herein are included for
                      convenience and identification only and are not intended
                      to describe, interpret, define or limit the scope, extent
                      or intent of the Terms or the right to use the Website by
                      you contained herein or any other section or pages of the
                      Website or any Linked Sites in any manner whatsoever.
                    </p>
                    <br />
                    <b>Interpretation Of Number And Genders:</b>
                    <p>
                      The Terms herein shall apply equally to both the singular
                      and plural form of the terms defined. Whenever the context
                      may require, any pronoun shall include the corresponding
                      masculine and feminine. The words "include", "includes"
                      and "including" shall be deemed to be followed by the
                      phrase "without limitation". Unless the context otherwise
                      requires, the terms "herein", "hereof", "hereto",
                      "hereunder" and words of similar import refer to the Terms
                      as a whole.
                    </p>
                    <br />
                    <b>Severability:</b>
                    <p>
                      If any provision of the Terms is determined to be invalid
                      or unenforceable in whole or in part, such invalidity or
                      unenforceability shall attach only to such provision or
                      part of such provision and the remaining part of such
                      provision and all other provisions of these Terms shall
                      continue to be in full force and effect.
                    </p>
                    <br />
                    <b>Report Abuse:</b>
                    <p>
                      As per these Terms, users are solely responsible for every
                      material or content uploaded on to the Website.
                      VilvaNetworks does not review the contents in any way
                      before they appear on the Website. VilvaNetworks does not
                      verify, endorse or otherwise vouch for the contents of any
                      user or any content generally posted or uploaded on to the
                      Website. Users can be held legally liable for their
                      contents and may be held legally accountable if their
                      contents or material include, for example, defamatory
                      comments or material protected by copyright, trademark,
                      etc. If you come across any abuse or violation of these
                      Terms, please report to{" "}
                      <a
                        href="/cdn-cgi/l/email-protection"
                        className="__cf_email__"
                        data-cfemail="fd9e8ebd9c99849c8f959c9399949e8f9c9b898ed39e9290"
                      >
                        [email&nbsp;protected]
                      </a>
                      .
                    </p>
                    <br />
                  </>
                </>
              </div>
            </div>
          </div>
        </section>

        <section className="py-4 bg-blue-300 footerStyle">
          <div className="container mx-auto px-2">
            <div className="flex flex-wrap -mx-4 pb-6 lg:pb-6 border-b border-gray-400">
              <div className="w-full lg:w-3/5 px-4 mb-6">
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-2 text-xl font-bold font-heading text-white">
                      Information
                    </h3>
                    <ul>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href="privacypolicy"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href="termsandconditions"
                        >
                          Terms And Condtions
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href="shippingandrefundpolicy"
                        >
                          Shipping And Refund Policy
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Investor Relations
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Reward program
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Delivery information
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Paying by invoice
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-2 text-xl font-bold font-heading text-white">
                      Customer Service
                    </h3>
                    <ul>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Return an order
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Search Terms
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Advanced Search
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Orders and Returns
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          FAQs
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Store Location
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-4">
                    <h3 className="mb-2 text-xl text-white font-bold font-heading">
                      Contact Us
                    </h3>
                    <ul>
                      <li className="mb-2">
                        <h4 className="mb-2 text-gray-50">Mobile</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          +91 95 517 89459
                        </a>
                      </li>
                      <li className="mb-2">
                        <h4 className="mb-2 text-gray-50">Email</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          contact@tamiltshirts.com
                        </a>
                      </li>
                      <li>
                        <h4 className="mb-2 text-gray-50">Live Chat</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          tamiltshirts
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-2/5 px-4 order-first lg:order-1 mb-2">
                <h3 className="mb-2 text-xl text-white font-bold font-heading">
                  Join our Newsletter
                </h3>
                <p className="mb-2 text-xl text-yellow-500 font-bold font-heading">
                  News, sales:
                </p>
                <div className="mb-2 relative lg:max-w-xl lg:mx-auto bg-white rounded-lg">
                  <div className="relative flex flex-wrap items-center justify-between">
                    <div className="relative flex-1">
                      {/* <span className="absolute top-0 left-0 ml-8 mt-4 font-semibold font-heading text-xs text-gray-400">
                      Drop your e-mail
                    </span> */}
                      <input
                        className="inline-block w-full px-8 placeholder-gray-900 border-0 focus:ring-transparent focus:outline-none rounded-md"
                        type="text"
                        placeholder="Drop your e-mail"
                      />
                    </div>
                    <a
                      className="inline-block w-auto bg-orange-300 hover:bg-orange-400 text-white font-bold font-heading py-2 px-8 rounded-md uppercase text-center"
                      href={() => {
                        return false;
                      }}
                    >
                      Join
                    </a>
                  </div>
                </div>
                <p className="text-gray-200">
                  The brown fox jumps over the lazy dog.
                </p>
              </div>
              <div className="w-full px-4 flex flex-wrap justify-between lg:order-last">
                <div className="w-full md:w-auto mb-4 md:mb-0 flex flex-wrap">
                  <img
                    className="mr-4 mb-2"
                    src="/yofte-assets/brands/visa.svg"
                    alt=""
                  />
                  <img
                    className="mr-4 mb-2"
                    src="/yofte-assets/brands/paypal.svg"
                    alt=""
                  />
                  <img
                    className="mb-2"
                    src="/yofte-assets/brands/mastercard.svg"
                    alt=""
                  />
                </div>
                <div className="w-full md:w-auto flex">
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/facebook-white-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/instagram-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/twitter-circle.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="pt-15   flex items-center justify-center">
              <a
                className="inline-block mr-4 text-white text-2xl font-bold font-heading"
                href="/"
              >
                <img
                  className="h-7"
                  src={`/yofte-assets/logos/${lowercasenosp(
                    store
                  )}/logowhite.webp`}
                  alt=""
                  width="auto"
                />
              </a>
              <p className="inline-block text-sm text-gray-200">
                {footercopyrighttext}
              </p>
            </div>
            <br />
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
