import React, { useCallback, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import { AxiosPost } from "../utilities/axioscall";
import { AK } from "../constants/AppKeys";
import { AxiosError } from "../utilities/sessionexpiry";
import { useDispatch } from "react-redux";
import { setUser } from "../store/user/actions";
import { getUserdata } from "../utilities/sessionexpiry";
import { checkerArray } from "../utilities/checker";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { lowercasenosp } from "../utilities/checker";
const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function SignIn(props) {
  const {
    store,
    description,
    assets,
    storeid,
    hdimage,
    productimage,
    productviewimage,
    thumbnailviewimage,
    cartinfoData,
    pageRefresh,
    footercopyrighttext,
    showFooter,
    setshowFooter,
  } = props;

  const navigate = useNavigate();
  const [pageinit, setPageInit] = useState(false);
  const [userData, setUserData] = useState({});
  const [signinformData, setSignInFormData] = useState({
    username: "",
    password: "",
    usernameError: false,
    passwordError: false,
    credentialsError: false,
    formloading: false,
    formsuccessmsg: false,
  });
  const [count, setCount] = useState(1);
  let dispatch = useDispatch();

  useEffect(() => {
    console.log("***");
  }, [count]);
  const handleSignIn = useCallback(async () => {
    let error = false;
    changeInput(false, "usernameError");
    changeInput(false, "passwordError");
    changeInput(false, "credentialsError");
    changeInput(false, "formloading");
    changeInput(false, "formsuccessmsg");

    if (signinformData.username === "") {
      await changeInput(true, "usernameError");
      error = true;
    }
    if (signinformData.password === "") {
      await changeInput(true, "passwordError");
      error = true;
    }

    if (error) return;
    const loginpayload = {
      username: signinformData.username,
      password: signinformData.password,
    };
    await changeInput(true, "formloading");
    await AxiosPost(AK.LOGINAPI, loginpayload, false)
      .then(async (res) => {
        changeInput(false, "formloading");

        if (res != typeof undefined && res.data != typeof undefined) {
          changeInput(true, "formsuccessmsg");
          let userdata = JSON.parse(JSON.stringify(res.data));
          dispatch(setUser(userdata));

          if (
            cartinfoData?.products &&
            checkerArray(cartinfoData?.products, 1)
          ) {
            navigate("/checkout");
          } else {
            navigate("/");
          }
        }
      })
      .catch(async (error) => {
        let errors = AxiosError(error);
        console.log(errors);
        if (errors?.message === "Invalid credentials") {
          await changeInput(false, "formloading");
          await changeInput(true, "credentialsError");
        } else {
          await changeInput(false, "formloading");
          await changeInput(true, "credentialsError");
        }
      });
  });

  const changeInput = useCallback(async (value, field) => {
    signinformData[field] = value;
    setSignInFormData(signinformData);
    setCount((count) => (count = count + 1));
  });

  const handleLogout = async () => {
    AxiosPost(AK.LOGOUTAPI, {})
      .then((res) => {
        dispatch(setUser(null));
      })
      .catch((error) => {
        dispatch(setUser(null));
      });
    dispatch(setUser(null));
  };

  if (!pageinit) {
    setPageInit(true);
    // setUserData(getUserdata());
    handleLogout();
    pageRefresh();
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className="overflow-x-hidden container-y">
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap items-center">
              <div className="w-full lg:w-2/6 px-4 mb-12 lg:mb-0">
                <div className="py-4 testing-1 text-center">
                  <a
                    className="inline-block mb-14 text-3xl font-bold font-heading"
                    href="/"
                  >
                    <img
                      className="h-16"
                      src={`yofte-assets/logos/${lowercasenosp(
                        store
                      )}/logo.webp`}
                      alt=""
                      width="auto"
                    />
                  </a>
                  <h3 className="mb-8 text-3xl md:text-3xl font-bold font-heading">
                    Sign In
                  </h3>
                  <p className="mb-10">
                    Please, do sign in to check out your cart!
                  </p>
                  <form action="">
                    <input
                      className="w-full mb-4 px-12 py-2 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                      type="text"
                      placeholder="contact@tamiltshirts.in"
                      value={signinformData.username}
                      onChange={(e) => changeInput(e.target.value, "username")}
                    />
                    <p
                      className="mb-2 text-red-500"
                      hidden={!signinformData.usernameError}
                    >
                      Please, enter your user name!
                    </p>
                    <p
                      className="mb-2 text-red-500"
                      hidden={!signinformData.credentialsError}
                    >
                      These credentials do not match our records.
                    </p>
                    <input
                      className="w-full mb-4 px-12 py-2 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                      type="password"
                      placeholder="Password"
                      onChange={(e) => changeInput(e.target.value, "password")}
                    />
                    <p
                      className="mb-2 text-red-500"
                      hidden={!signinformData.passwordError}
                    >
                      Please, enter your password!
                    </p>
                    <label className="flex" htmlFor="rememberme">
                      <input
                        id="rememberme"
                        className="mr-4 mt-0"
                        type="checkbox"
                      />
                      <span className="text-sm">Remember me.</span>
                    </label>
                    <PulseLoader
                      loading={signinformData.formloading}
                      size={10}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                    <p
                      className="mb-2 text-green-500"
                      hidden={!signinformData.formsuccessmsg}
                    >
                      Logged In Successfully, Shop Now.
                    </p>
                    <br />
                    <button
                      className="mt-2 md:mt-2 bg-blue-800 hover:bg-blue-900 text-white font-bold font-heading py-2 px-8 rounded-md uppercase"
                      onClick={async (event) => {
                        event.preventDefault();
                        let eventtarget = event.currentTarget;
                        if (eventtarget.readOnly) return;
                        eventtarget.readOnly = true;
                        await handleSignIn();
                        eventtarget.readOnly = false;
                      }}
                    >
                      Sign In
                    </button>
                    <br />
                    (or)
                    <br />
                    <button
                      className="bg-blue-800 hover:bg-blue-900 text-white font-bold font-heading py-2 px-8 rounded-md uppercase"
                      onClick={() => {
                        navigate("/signup");
                      }}
                    >
                      Create A New Account
                    </button>
                  </form>
                </div>
                <img
                  className="hidden lg:hidden h-96 w-full object-cover"
                  src="yofte-assets/images/placeholder-sign.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            className="hidden lg:block lg:absolute top-0 bottom-0 right-0 lg:w-3/6 bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage:
                'url("yofte-assets/images/placeholder-sign.png")',
            }}
          />
        </section>
      </>
    </React.Fragment>
  );
}
