import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { PiCurrencyInr } from "react-icons/pi";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import {
  handleCartMinus,
  handleCartPlus,
  handleFetchCategoryData,
  handleFetchProductsData,
  handleGetCartInfoStorageItems,
} from "../utilities/cartManager";
import "./customstyle.css";
import { getUserdata } from "../utilities/sessionexpiry";
import { lowercasenosp } from "../utilities/checker";
import HomeSlider from "./homeComponents/HomeSlider/HomeSlider";
import MensProduct from "./homeComponents/Products-Men/MensProduct";
import WomenProduct from "./homeComponents/Products-Women/WomenProduct";
import { Accessories } from "./homeComponents/Accessories/Accessories";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index(props) {
  const {
    store,
    description,
    assets,
    storeid,
    hdimage,
    productimage,
    productviewimage,
    thumbnailviewimage,
    mainpagebannerbgcolor,
    mainpageheader1text,
    mainpageheader2text,
    mainpagebanner1text,
    mainpagebanner2text,
    footercopyrighttext,
    showFooter,
    setshowFooter,
  } = props;
  const [pageinit, setPageInit] = useState(false);
  const [showFormLoader, setFormLoader] = useState(false);
  const [categoryListData, setCategoryListData] = useState({});
  const [cartinfoData, setCartInfoData] = useState({
    cartcount: 0,
    cartquantitycount: 0,
    products: [],
    cartprice: 0,
    subtotal: 0,
    shipping: 0,
    ordertotal: 0,
    total: 0,
  });
  const [count, setCount] = useState(1);
  const [productsListData, setProductsListData] = useState([]);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    console.log("***");
  }, [count]);

  const getcartCount = () => {
    // return cartinfoData.cartcount;
    return cartinfoData.cartcount;
  };

  if (!pageinit) {
    setPageInit(true);
    setUserData(getUserdata());
    // handleFetchCategoryData(setFormLoader, setCategoryListData);
    // handleFetchProductsData(setFormLoader, setProductsListData);
    handleGetCartInfoStorageItems(setCartInfoData);
    setCount((count) => (count = count + 1));
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section
          className="relative"
          style={{ backgroundColor: mainpagebannerbgcolor }}
        >
          <nav
            style={{ fontWeight: 500, fontSize: "13px" }}
            className="flex justify-between border-b navcustombg"
          >
            <div className="px-6 py-2 flex w-full items-center">
              {/* <a className="hidden xl:block mr-16" href='javascript:;'>
                <svg
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18 15.4688H0V17.7207H18V15.4688Z" fill="black" />
                  <path
                    d="M11.0226 7.87402H0V10.126H11.0226V7.87402Z"
                    fill="black"
                  />
                  <path d="M18 0.279297H0V2.53127H18V0.279297Z" fill="black" />
                </svg>
              </a> */}
              <ul className="hidden xl:flex font-heading">
                <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products/men">
                    MEN
                  </a>
                </li>
                <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products/women">
                    WOMEN
                  </a>
                </li>
                <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products/kids">
                    KIDS
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    className="hover:text-gray-600"
                    href="/products/couplecollection"
                  >
                    COUPLE COLLECTION
                  </a>
                </li>
                {/* <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products">
                    Apparels
                  </a>
                </li> */}
                {/* <li>
                  <a
                    className="hover:text-gray-600"
                    href="/products/politicalshirts"
                  >
                    POLITICAL SHIRTS
                  </a>
                </li> */}
                <li>
                  <a className="hover:text-gray-600" href="/products/apparels">
                    APPARELS
                  </a>
                </li>
              </ul>
              <a
                className="flex-shrink-0 xl:mx-auto text-3xl font-bold font-heading"
                href="/"
              >
                <img
                  style={{ height: "38px" }}
                  className="h-12"
                  src={`/yofte-assets/logos/${lowercasenosp(store)}/logo.webp`}
                  alt=""
                  width="auto"
                />
              </a>
              {/* <div className="hidden xl:inline-block mr-14">
                <input
                  className="py-5 px-8 w-full placeholder-gray-400 text-xs uppercase font-semibold font-heading bg-gray-50 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                  type="text"
                  placeholder="Search"
                />
              </div> */}

              <div className="hidden xl:flex items-center">
                {/* <div className="pull-right mainHeaderCols activemenuwrp">
                  <div className="actionMenu" style={{ padding: "15px" }}>
                    <span
                      className="actionMenu actionMenuInner"
                      id="testHeaderCart"
                    >
                      <a
                        href="/cart"
                        className="cartIcon"
                        style={{ paddingRight: 16, position: "relative" }}
                      >
                        <i className="icon_bag" aria-hidden="true" />
                        <span className="cartCount"> {getcartCount()}</span>
                      </a>
                    </span>
                    <span
                      className="actionMenu"
                      id="testHeadWish"
                      style={{ padding: "0px 5px", cursor: "pointer" }}
                    >
                      <div>
                        <div className="">
                          <i
                            className="icon_wishlist"
                            style={{ fontSize: "20px" }}
                          />
                        </div>
                      </div>
                    </span>
                    <a href="#" id="testHeaderAcc">
                      <i className="icon_user" />
                    </a>
                  </div>
                </div> */}

                <a className="mr-2 hover:text-gray-600" href="#">
                  <svg
                    width={23}
                    height={20}
                    viewBox="0 0 23 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4998 19.2061L2.70115 9.92527C1.92859 9.14433 1.41864 8.1374 1.24355 7.04712C1.06847 5.95684 1.23713 4.8385 1.72563 3.85053V3.85053C2.09464 3.10462 2.63366 2.45803 3.29828 1.96406C3.9629 1.47008 4.73408 1.14284 5.5483 1.00931C6.36252 0.875782 7.19647 0.939779 7.98144 1.19603C8.7664 1.45228 9.47991 1.89345 10.0632 2.48319L11.4998 3.93577L12.9364 2.48319C13.5197 1.89345 14.2332 1.45228 15.0182 1.19603C15.8031 0.939779 16.6371 0.875782 17.4513 1.00931C18.2655 1.14284 19.0367 1.47008 19.7013 1.96406C20.3659 2.45803 20.905 3.10462 21.274 3.85053V3.85053C21.7625 4.8385 21.9311 5.95684 21.756 7.04712C21.581 8.1374 21.071 9.14433 20.2984 9.92527L11.4998 19.2061Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <a
                  className="flex items-center hover:text-gray-600"
                  href="/cart"
                >
                  {/* <svg
                    className="mr-3"
                    width={23}
                    height={23}
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1159 8.72461H2.50427C1.99709 8.72461 1.58594 9.12704 1.58594 9.62346V21.3085C1.58594 21.8049 1.99709 22.2074 2.50427 22.2074H18.1159C18.6231 22.2074 19.0342 21.8049 19.0342 21.3085V9.62346C19.0342 9.12704 18.6231 8.72461 18.1159 8.72461Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.34473 6.34469V4.95676C6.34473 3.85246 6.76252 2.79338 7.5062 2.01252C8.24988 1.23165 9.25852 0.792969 10.3102 0.792969C11.362 0.792969 12.3706 1.23165 13.1143 2.01252C13.858 2.79338 14.2758 3.85246 14.2758 4.95676V6.34469"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span
                    style={{ padding: "3px" }}
                    className="inline-block w-6 h-6 text-center text-white bg-orange-300  hover:bg-orange-400 rounded-full font-heading"
                  >
                    {getcartCount()}
                  </span> */}
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/cart">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="iconoir:cart">
                        <g id="Group">
                          <path
                            id="Vector"
                            d="M19.5 22.2592C19.8978 22.2592 20.2794 22.1006 20.5607 21.8183C20.842 21.5359 21 21.153 21 20.7537C21 20.3544 20.842 19.9715 20.5607 19.6891C20.2794 19.4068 19.8978 19.2481 19.5 19.2481C19.1022 19.2481 18.7206 19.4068 18.4393 19.6891C18.158 19.9715 18 20.3544 18 20.7537C18 21.153 18.158 21.5359 18.4393 21.8183C18.7206 22.1006 19.1022 22.2592 19.5 22.2592ZM9.5 22.2592C9.89782 22.2592 10.2794 22.1006 10.5607 21.8183C10.842 21.5359 11 21.153 11 20.7537C11 20.3544 10.842 19.9715 10.5607 19.6891C10.2794 19.4068 9.89782 19.2481 9.5 19.2481C9.10218 19.2481 8.72064 19.4068 8.43934 19.6891C8.15804 19.9715 8 20.3544 8 20.7537C8 21.153 8.15804 21.5359 8.43934 21.8183C8.72064 22.1006 9.10218 22.2592 9.5 22.2592Z"
                            fill="#000000"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M5 4.19259H22L20 15.2333M5 4.19259L7 15.2333H20M5 4.19259C4.833 3.52312 4 2.18518 2 2.18518M20 15.2333H5.23C3.446 15.2333 2.5 16.0172 2.5 17.2407C2.5 18.4642 3.446 19.2481 5.23 19.2481H19.5"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </g>
                    </svg>
                  </a> */}
                  <div className="pull-right mainHeaderCols activemenuwrp">
                    <div className="actionMenu" style={{ padding: "10px" }}>
                      <span
                        className="actionMenu actionMenuInner"
                        id="testHeaderCart"
                      >
                        <a
                          href="/cart"
                          className="cartIcon"
                          style={{ paddingRight: 16, position: "relative" }}
                        >
                          {/* <i className="icon_bag" aria-hidden="true" /> */}
                          <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                          <span className="cartCount"> {getcartCount()}</span>
                        </a>
                      </span>

                      {/* <span
                        className="actionMenu actionMenuInner"
                        id="testHeaderCart"
                      >
                        <a
                          href="/cart"
                          className="cartIcon"
                          style={{ paddingRight: 16, position: "relative" }}
                        ></a>
                      </span> */}
                      {/* <span className="cartCount"> {getcartCount()}</span> */}

                      {/* <span className="cartCount"> {getcartCount()}</span> */}

                      {/* <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="iconoir:cart">
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M19.5 22.2592C19.8978 22.2592 20.2794 22.1006 20.5607 21.8183C20.842 21.5359 21 21.153 21 20.7537C21 20.3544 20.842 19.9715 20.5607 19.6891C20.2794 19.4068 19.8978 19.2481 19.5 19.2481C19.1022 19.2481 18.7206 19.4068 18.4393 19.6891C18.158 19.9715 18 20.3544 18 20.7537C18 21.153 18.158 21.5359 18.4393 21.8183C18.7206 22.1006 19.1022 22.2592 19.5 22.2592ZM9.5 22.2592C9.89782 22.2592 10.2794 22.1006 10.5607 21.8183C10.842 21.5359 11 21.153 11 20.7537C11 20.3544 10.842 19.9715 10.5607 19.6891C10.2794 19.4068 9.89782 19.2481 9.5 19.2481C9.10218 19.2481 8.72064 19.4068 8.43934 19.6891C8.15804 19.9715 8 20.3544 8 20.7537C8 21.153 8.15804 21.5359 8.43934 21.8183C8.72064 22.1006 9.10218 22.2592 9.5 22.2592Z"
                              fill="#000000"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              id="Vector_2"
                              d="M5 4.19259H22L20 15.2333M5 4.19259L7 15.2333H20M5 4.19259C4.833 3.52312 4 2.18518 2 2.18518M20 15.2333H5.23C3.446 15.2333 2.5 16.0172 2.5 17.2407C2.5 18.4642 3.446 19.2481 5.23 19.2481H19.5"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </g>
                      </svg> */}

                      {/* <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="iconoir:cart">
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M19.5 22.2592C19.8978 22.2592 20.2794 22.1006 20.5607 21.8183C20.842 21.5359 21 21.153 21 20.7537C21 20.3544 20.842 19.9715 20.5607 19.6891C20.2794 19.4068 19.8978 19.2481 19.5 19.2481C19.1022 19.2481 18.7206 19.4068 18.4393 19.6891C18.158 19.9715 18 20.3544 18 20.7537C18 21.153 18.158 21.5359 18.4393 21.8183C18.7206 22.1006 19.1022 22.2592 19.5 22.2592ZM9.5 22.2592C9.89782 22.2592 10.2794 22.1006 10.5607 21.8183C10.842 21.5359 11 21.153 11 20.7537C11 20.3544 10.842 19.9715 10.5607 19.6891C10.2794 19.4068 9.89782 19.2481 9.5 19.2481C9.10218 19.2481 8.72064 19.4068 8.43934 19.6891C8.15804 19.9715 8 20.3544 8 20.7537C8 21.153 8.15804 21.5359 8.43934 21.8183C8.72064 22.1006 9.10218 22.2592 9.5 22.2592Z"
                              fill="#000000"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              id="Vector_2"
                              d="M5 4.19259H22L20 15.2333M5 4.19259L7 15.2333H20M5 4.19259C4.833 3.52312 4 2.18518 2 2.18518M20 15.2333H5.23C3.446 15.2333 2.5 16.0172 2.5 17.2407C2.5 18.4642 3.446 19.2481 5.23 19.2481H19.5"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </g>
                      </svg> */}

                      {/* <a
                        href="/cart"
                        className="cartIcon"
                        style={{ paddingRight: 16, position: "relative" }}
                      >
                        <i className="icon_bag" aria-hidden="true" />
                        <span className="cartCount"> {getcartCount()}</span>
                      </a> */}
                      {/* <span
                        className="actionMenu"
                        id="testHeadWish"
                        style={{ padding: "0px 5px", cursor: "pointer" }}
                      >
                        <div>
                          <div className="">
                            <i
                              className="icon_wishlist"
                              style={{ fontSize: "20px" }}
                            />
                          </div>
                        </div>
                      </span>
                      <a href="#" id="testHeaderAcc">
                        <i className="icon_user" />
                      </a> */}
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="hidden xl:flex items-center px-12 border-l font-heading hover:text-gray-600">
              <a
                href="/signin"
                style={{
                  display: userData != null ? "none" : "flex",
                }}
              >
                <svg
                  className="mr-3"
                  width={32}
                  height={31}
                  viewBox="0 0 32 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span style={{ width: "150px", paddingTop: "5px" }}>
                  SIGN&nbsp;IN
                </span>
              </a>

              <a
                className="dropdown"
                href={() => {
                  return false;
                }}
                style={{
                  display: userData === null ? "none" : "flex",
                  cursor: "pointer",
                }}
              >
                <svg
                  className="mr-3"
                  width={32}
                  height={31}
                  viewBox="0 0 32 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span style={{ width: "150px", paddingTop: "5px" }}>
                  {userData?.name}
                </span>
                <div className="dropdown-content">
                  <a href="/myprofile">My Profile</a>
                  <a href="/orderhistory">My Orders</a>
                  <a href="/changepassword">Change Password</a>
                  <hr
                    style={{
                      border: "1px solid #CFD5E2",
                      height: "2px",
                    }}
                  />
                  <a href="/signin">Logout</a>
                </div>
              </a>
            </div>
            {/* <div className="pull-right mainHeaderCols activemenuwrp">
              <div className="actionMenu" style={{ padding: "10px" }}>
                <span
                  className="actionMenu actionMenuInner"
                  id="testHeaderCart"
                >
                  <a
                    href="/cart"
                    className="cartIcon"
                    style={{ paddingRight: 16, position: "relative" }}
                  >
                    <i className="icon_bag" aria-hidden="true" />
                    <span className="cartCount"> {getcartCount()}</span>
                  </a>
                </span>
              </div>
            </div> */}

            <a
              className="xl:hidden flex mr-6 items-center text-gray-900"
              href="/cart"
            >
              {/* <svg
                className="mr-2"
                width={23}
                height={23}
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.1159 8.72461H2.50427C1.99709 8.72461 1.58594 9.12704 1.58594 9.62346V21.3085C1.58594 21.8049 1.99709 22.2074 2.50427 22.2074H18.1159C18.6231 22.2074 19.0342 21.8049 19.0342 21.3085V9.62346C19.0342 9.12704 18.6231 8.72461 18.1159 8.72461Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.34473 6.34469V4.95676C6.34473 3.85246 6.76252 2.79338 7.5062 2.01252C8.24988 1.23165 9.25852 0.792969 10.3102 0.792969C11.362 0.792969 12.3706 1.23165 13.1143 2.01252C13.858 2.79338 14.2758 3.85246 14.2758 4.95676V6.34469"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span
                style={{ padding: "3px" }}
                className="inline-block w-6 h-6 text-center text-white bg-orange-300  hover:bg-orange-400 rounded-full font-heading"
              >
                {getcartCount()}
              </span> */}
            </a>
            <a
              className="navbar-burger self-center mr-12 xl:hidden"
              href={() => {
                return false;
              }}
              onClick={() => {
                document.getElementById("mySidenav").style.width = "290px";
              }}
            >
              <svg
                width={20}
                height={12}
                viewBox="0 0 20 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 2H19C19.2652 2 19.5196 1.89464 19.7071 1.70711C19.8946 1.51957 20 1.26522 20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM19 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H19C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10ZM19 5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H19C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5Z"
                  fill="#8594A5"
                />
              </svg>
            </a>
            <div id="mySidenav" className="sidenav mr-12 xl:hidden">
              <a
                href={() => {
                  return false;
                }}
                className="closebtn"
                onClick={() => {
                  document.getElementById("mySidenav").style.width = "0";
                }}
              >
                &times;
              </a>
              <div className="xl:flex items-center border-l font-heading hover:text-gray-600">
                <a
                  href="/signin"
                  style={{
                    display: userData != null ? "none" : "flex",
                  }}
                >
                  <svg
                    className="mr-3"
                    width={32}
                    height={31}
                    viewBox="0 0 32 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span style={{ width: "150px", paddingTop: "5px" }}>
                    SIGN&nbsp;IN
                  </span>
                </a>

                <a
                  className="dropdown"
                  href="#"
                  style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    className="mr-3"
                    width={32}
                    height={31}
                    viewBox="0 0 32 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span style={{ width: "150px", paddingTop: "0px" }}>
                    {userData?.name}
                  </span>
                  <div className="dropdown-content-nav">
                    <a href="/myprofile">My Profile</a>
                    <a href="/orderhistory">My Orders</a>
                    <a href="/changepassword">Change Password</a>
                    <hr
                      style={{
                        border: "1px solid #CFD5E2",
                        height: "2px",
                      }}
                    />
                    <a href="/signin">Logout</a>
                  </div>
                </a>
              </div>
              <hr
                style={{
                  border: "1px solid #CFD5E2",
                  height: "2px",
                }}
              />
              <ul className="xl:flex" style={{ fontSize: "6px" }}>
                <li className="mr-12 ">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/men"
                  >
                    🕺 &nbsp;&nbsp; Men
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/women"
                  >
                    💃 &nbsp;&nbsp; Women
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/kids"
                  >
                    👶 &nbsp;&nbsp; Kids
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/couplecollection"
                  >
                    👩‍❤️‍👨 &nbsp;&nbsp; Couple Collection
                  </a>
                </li>
                {/* <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products">
                    Apparels
                  </a>
                </li> */}
                <li>
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/Apparels"
                  >
                    🚩 &nbsp;&nbsp; APPARELS
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          {/* <div className="relative container mx-auto px-4">
            <img
              id="bgimg"
              className="absolute bottom-0 inset-x-0 w-full h-80 -mb-16 lg:mb-0 md:h-full object-cover"
              src={`yofte-assets/images/${lowercasenosp(store)}/banner.png`}
              alt=""
            />
            <div className="hidden lg:flex absolute right-0 top-1/2 transform -translate-y-1/2 flex-col items-center">
              <span className="mb-5 font-bold font-heading">01</span>
              <div className="mb-5 h-16 w-px bg-gray-100" />
              <button className="mb-5">
                <img src="yofte-assets/elements/circle.svg" alt="" />
              </button>
              <button
                className="mb-6 w-1 h-1 bg-blue-500 rounded-full"
                onClick={() => {
                  document.getElementById("bgimg").src =
                    "yofte-assets/images/women.webp";
                }}
              >
                <img src="yofte-assets/elements/circle.svg" alt="" />
              </button>
              <button className="mb-6 w-1 h-1 bg-blue-500 rounded-full" />
              <div className="h-16 w-px bg-gray-100" />
            </div>
            <div className="relative flex flex-wrap -mx-4">
              <div className="w-full md:w-1/2 px-4 mb-12 lg:mb-0 pt-20 lg:pt-32 pb-32 lg:pb-64">
                <div>
                  <h2
                    className="mb-8 text-2xl lg:text-3xl font-heading"
                    dangerouslySetInnerHTML={{ __html: mainpageheader1text }}
                  ></h2>
                  <p className="mb-20 text-lg text-gray-600">
                    {mainpageheader2text}
                  </p>
                  <a
                    className="inline-block bg-orange-300 hover:bg-orange-400 text-white font-bold font-heading py-2 px-8 rounded-md uppercase transition duration-200"
                    href="/products"
                  >
                    Shop Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="relative bg-gray-100 py-4 testing-1">
            <div className="container mx-auto px-4">
              <div className="flex lg:-mt-52 mb-20 flex-wrap justify-center">
                <div
                  className="relative h-64 w-full lg:w-96 mb-6 lg:mb-0 lg:mr-6 bg-no-repeat bg-cover bg-right"
                  style={{
                    backgroundImage: `url("yofte-assets/images/${lowercasenosp(
                      store
                    )}/womanclothing1.jpg")`,
                    boxSizing: "border-box",

                    borderRadius: "5%",
                  }}
                >
                  <a
                    className="absolute inset-0 flex items-end"
                    href="/products"
                  >
                    <div className="pl-12 pb-12">
                      <h3 className="text-3xl font-bold font-heading text-white">
                        {mainpagebanner1text}
                      </h3>
                      <p className="text-xl text-white font-bold font-heading">
                        <span style={{ display: "flex" }}>
                          <span style={{ paddingTop: "5px" }}>
                            <PiCurrencyInr style={{ color: "#ffffff" }} />
                          </span>
                          600
                          <span
                            className="text-sm font-normal line-through"
                            style={{ paddingTop: "5px" }}
                          >
                            800
                          </span>
                        </span>
                      </p>
                    </div>
                  </a>
                </div>
                <div
                  className="relative h-64 w-full lg:w-96 bg-no-repeat bg-cover"
                  style={{
                    backgroundImage: `url("yofte-assets/images/${lowercasenosp(
                      store
                    )}/womanclothing2.jpg")`,
                    boxSizing: "border-box",

                    borderRadius: "5%",
                  }}
                >
                  <a
                    className="absolute inset-0 flex items-end"
                    href="/products"
                  >
                    <div className="pl-12 pb-12">
                      <h3 className="text-3xl font-bold font-heading text-white">
                        {mainpagebanner2text}
                      </h3>
                      <p className="text-xl text-white font-bold font-heading">
                        <span style={{ display: "flex" }}>
                          <span style={{ paddingTop: "5px" }}>
                            <PiCurrencyInr style={{ color: "#ffffff" }} />
                          </span>
                          600
                          <span
                            className="text-sm font-normal line-through"
                            style={{ paddingTop: "5px" }}
                          >
                            800
                          </span>
                        </span>
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="max-w-5xl mx-auto flex flex-wrap items-center justify-center -mb-12">
                <div className="w-1/2 md:w-1/3 lg:w-1/6 px-2 mb-12">
                  <img
                    className="mx-auto h-6"
                    src="yofte-assets/brands/exxon.svg"
                    alt=""
                  />
                </div>
                <div className="w-1/2 md:w-1/3 lg:w-1/6 px-2 mb-12">
                  <img
                    className="mx-auto h-6"
                    src="yofte-assets/brands/ea-sports.svg"
                    alt=""
                  />
                </div>
                <div className="w-1/2 md:w-1/3 lg:w-1/6 px-2 mb-12">
                  <img
                    className="mx-auto h-6"
                    src="yofte-assets/brands/eurosport.svg"
                    alt=""
                  />
                </div>
                <div className="w-1/2 md:w-1/3 lg:w-1/6 px-2 mb-12">
                  <img
                    className="mx-auto h-6"
                    src="yofte-assets/brands/nike.svg"
                    alt=""
                  />
                </div>
                <div className="w-1/2 md:w-1/3 lg:w-1/6 px-2 mb-12">
                  <img
                    className="mx-auto h-6"
                    src="yofte-assets/brands/aol.svg"
                    alt=""
                  />
                </div>
                <div className="w-1/2 md:w-1/3 lg:w-1/6 px-2 mb-12">
                  <img
                    className="mx-auto h-6"
                    src="yofte-assets/brands/north-face.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
            <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" />
            <nav className="relative flex flex-col py-6 px-6 w-full h-full bg-white border-r overflow-y-auto">
              <div className="flex items-center mb-8">
                <a className="mr-auto text-3xl font-bold font-heading" href="#">
                  <img
                    className="h-10"
                    src={`/yofte-assets/logos/${lowercasenosp(store)}/logo.webp`}
                    alt=""
                    width="auto"
                  />
                </a>
                <button className="navbar-close">
                  <svg
                    className="h-2 w-2 text-gray-500 cursor-pointer"
                    width={10}
                    height={10}
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.00002 1L1 9.00002M1.00003 1L9.00005 9.00002"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex mb-8 justify-between">
                <a
                  className="inline-flex items-center font-semibold font-heading"
                  href="#"
                >
                  <svg
                    className="mr-3"
                    width={32}
                    height={31}
                    viewBox="0 0 32 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>SIGN&nbsp;IN</span>
                </a>
                <div className="flex items-center">
                  <a
                    className="mr-10"
                    href={() => {
                      return false;
                    }}
                  >
                    <svg
                      width={23}
                      height={20}
                      viewBox="0 0 23 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.4998 19.2061L2.70115 9.92527C1.92859 9.14433 1.41864 8.1374 1.24355 7.04712C1.06847 5.95684 1.23713 4.8385 1.72563 3.85053V3.85053C2.09464 3.10462 2.63366 2.45803 3.29828 1.96406C3.9629 1.47008 4.73408 1.14284 5.5483 1.00931C6.36252 0.875782 7.19647 0.939779 7.98144 1.19603C8.7664 1.45228 9.47991 1.89345 10.0632 2.48319L11.4998 3.93577L12.9364 2.48319C13.5197 1.89345 14.2332 1.45228 15.0182 1.19603C15.8031 0.939779 16.6371 0.875782 17.4513 1.00931C18.2655 1.14284 19.0367 1.47008 19.7013 1.96406C20.3659 2.45803 20.905 3.10462 21.274 3.85053V3.85053C21.7625 4.8385 21.9311 5.95684 21.756 7.04712C21.581 8.1374 21.071 9.14433 20.2984 9.92527L11.4998 19.2061Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                  <a
                    className="flex items-center"
                    href={() => {
                      return false;
                    }}
                  >
                    <svg
                      className="mr-3"
                      width={23}
                      height={23}
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.1159 8.72461H2.50427C1.99709 8.72461 1.58594 9.12704 1.58594 9.62346V21.3085C1.58594 21.8049 1.99709 22.2074 2.50427 22.2074H18.1159C18.6231 22.2074 19.0342 21.8049 19.0342 21.3085V9.62346C19.0342 9.12704 18.6231 8.72461 18.1159 8.72461Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.34473 6.34469V4.95676C6.34473 3.85246 6.76252 2.79338 7.5062 2.01252C8.24988 1.23165 9.25852 0.792969 10.3102 0.792969C11.362 0.792969 12.3706 1.23165 13.1143 2.01252C13.858 2.79338 14.2758 3.85246 14.2758 4.95676V6.34469"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span
                      style={{ padding: "3px" }}
                      className="inline-block w-6 h-6 text-center text-white bg-orange-300  hover:bg-orange-400 rounded-full font-heading"
                    >
                      3
                    </span>
                  </a>
                </div>
              </div>
              <input
                className="block mb-10 py-5 px-8 bg-gray-100 rounded-md border-transparent focus:ring-blue-300 focus:border-blue-300 focus:outline-none"
                type="search"
                placeholder="Search"
              />
              <ul className="text-3xl font-bold font-heading">
                <li className="mb-8">
                  <a href="/products">Category</a>
                </li>
                <li className="mb-8">
                  <a
                    href={() => {
                      return false;
                    }}
                  >
                    Collection
                  </a>
                </li>
                <li className="mb-8">
                  <a
                    href={() => {
                      return false;
                    }}
                  >
                    Story
                  </a>
                </li>
                <li>
                  <a
                    href={() => {
                      return false;
                    }}
                  >
                    Brand
                  </a>
                </li>
              </ul>
            </nav>
          </div> */}
        </section>

        <HomeSlider></HomeSlider>

        <MensProduct></MensProduct>

        <WomenProduct></WomenProduct>

        <Accessories></Accessories>

        {/* <section className="py-4 testing-1 bg-white container-y">
          <div className="container mx-auto px-4">
            <h2 className="mb-16 md:mb-24 text-4xl md:text-5xl font-bold font-heading">
              Discover our products
            </h2>
            <div className="flex flex-wrap -mx-3 mb-24">
              <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6 lg:mb-0">
                <div className="p-6 bg-gray-50">
                  <span className="px-2 py-1 text-xs font-bold font-heading border-2 border-red-500 rounded-full text-red-500 bg-white">
                    -15%
                  </span>
                  <a className="block px-6 mt-6 mb-2" href="/p/">
                    <img
                      className="mb-5 mx-auto h-56 w-full object-contain"
                      src="yofte-assets/images/waterbottle.webp"
                      alt=""
                    />
                    <h3 className="mb-2 text-xl font-bold font-heading">
                      BRILE water filter
                    </h3>
                    <p className="text-lg font-bold font-heading text-blue-500">
                      <span>$29.89</span>
                      <span className="text-xs text-gray-500 font-semibold font-heading line-through">
                        $33.69
                      </span>
                    </p>
                  </a>
                  <a
                    className="ml-auto mr-2 flex items-center justify-center w-12 h-12 border rounded-lg hover:border-gray-500"
                    href='javascript:;'
                  >
                    <svg
                      width={12}
                      height={12}
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x={5} width={2} height={12} fill="#161616" />
                      <rect
                        x={12}
                        y={5}
                        width={2}
                        height={12}
                        transform="rotate(90 12 5)"
                        fill="#161616"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6 lg:mb-0">
                <div className="p-6 bg-gray-50">
                  <span className="px-2 py-1 text-xs font-bold font-heading border-2 border-red-500 rounded-full text-red-500 bg-white">
                    -15%
                  </span>
                  <a className="block px-6 mt-6 mb-2" href="/p/">
                    <img
                      className="mb-5 mx-auto h-56 w-full object-contain"
                      src="yofte-assets/images/cycle.webp"
                      alt=""
                    />
                    <h3 className="mb-2 text-xl font-bold font-heading">
                      Bicycle S20
                    </h3>
                    <p className="text-lg font-bold font-heading text-blue-500">
                      <span>$14.30</span>
                      <span className="text-xs text-gray-500 font-semibold font-heading line-through">
                        $15.90
                      </span>
                    </p>
                  </a>
                  <a
                    className="ml-auto mr-2 flex items-center justify-center w-12 h-12 border rounded-lg hover:border-gray-500"
                    href='javascript:;'
                  >
                    <svg
                      width={12}
                      height={12}
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x={5} width={2} height={12} fill="#161616" />
                      <rect
                        x={12}
                        y={5}
                        width={2}
                        height={12}
                        transform="rotate(90 12 5)"
                        fill="#161616"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6 md:mb-0">
                <div className="p-6 bg-gray-50">
                  <span className="px-2 py-1" />
                  <a className="block px-6 mt-6 mb-2" href="/p/">
                    <img
                      className="mb-5 mx-auto h-56 w-full object-contain"
                      src="yofte-assets/images/basketball.webp"
                      alt=""
                    />
                    <h3 className="mb-2 text-xl font-bold font-heading">
                      Nike basketball ball
                    </h3>
                    <p className="text-lg font-bold font-heading text-blue-500">
                      <span>$34.89</span>
                      <span className="text-xs text-gray-500 font-semibold font-heading line-through">
                        $33.69
                      </span>
                    </p>
                  </a>
                  <a
                    className="ml-auto mr-2 flex items-center justify-center w-12 h-12 border rounded-lg hover:border-gray-500"
                    href='javascript:;'
                  >
                    <svg
                      width={12}
                      height={12}
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x={5} width={2} height={12} fill="#161616" />
                      <rect
                        x={12}
                        y={5}
                        width={2}
                        height={12}
                        transform="rotate(90 12 5)"
                        fill="#161616"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 px-3">
                <div className="p-6 bg-gray-50">
                  <span className="px-2 py-1 text-xs font-bold font-heading border-2 border-blue-300 rounded-full text-blue-300 bg-white">
                    NEW
                  </span>
                  <a className="block px-6 mt-6 mb-2" href="/p/">
                    <img
                      className="mb-5 mx-auto h-56 w-full object-contain"
                      src="yofte-assets/images/skateboard.webp"
                      alt=""
                    />
                    <h3 className="mb-2 text-xl font-bold font-heading">
                      Kiteboard WH-004
                    </h3>
                    <p className="text-lg font-bold font-heading text-blue-500">
                      <span>$199.90</span>
                      <span className="text-xs text-gray-500 font-semibold font-heading line-through">
                        $33.69
                      </span>
                    </p>
                  </a>
                  <a
                    className="ml-auto mr-2 flex items-center justify-center w-12 h-12 border rounded-lg hover:border-gray-500"
                    href='javascript:;'
                  >
                    <svg
                      width={12}
                      height={12}
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x={5} width={2} height={12} fill="#161616" />
                      <rect
                        x={12}
                        y={5}
                        width={2}
                        height={12}
                        transform="rotate(90 12 5)"
                        fill="#161616"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="text-center">
              <a
                className="inline-block bg-orange-300 hover:bg-orange-400 text-white font-bold font-heading py-6 px-8 rounded-md uppercase"
                href='javascript:;'
              >
                Show More
              </a>
            </div>
          </div>
        </section>
        <section className="relative py-4 testing-1 lg:pt-32 bg-blue-800 overflow-x-hidden">
          <img
            className="hidden md:block absolute top-0 left-0"
            src="yofte-assets/elements/white-line.svg"
            alt=""
          />
          <img
            className="absolute top-50 left-0 mt-64"
            src="yofte-assets/elements/orange-line.svg"
            alt=""
          />
          <img
            className="absolute bottom-0 right-0 w-72"
            src="yofte-assets/elements/violet-line.svg"
            alt=""
          />
          <div className="relative container mx-auto px-4">
            <h2 className="mb-16 text-4xl md:text-5xl text-white font-bold font-heading">
              The Most Popular
            </h2>
            <div className="flex flex-wrap -mx-3">
              <div className="w-full lg:w-1/3 px-3 mb-16 lg:mb-0">
                <a className="block mb-10" href="">
                  <div className="relative">
                    <span className="absolute bottom-0 left-0 ml-6 mb-6 px-2 py-1 text-xs font-bold font-heading bg-white border-2 border-red-500 rounded-full text-red-500">
                      -15%
                    </span>
                    <img
                      className="w-full h-96 object-cover"
                      src="yofte-assets/images/placeholder-playing-tennis.webp"
                      alt=""
                    />
                  </div>
                  <div className="mt-12">
                    <div className="mb-2">
                      <h3 className="mb-3 text-3xl text-white font-bold font-heading">
                        Tennis racket Sanks 2
                      </h3>
                      <p className="text-xl font-bold font-heading text-white">
                        <span>$295.30</span>
                        <span className="text-xs text-gray-500 font-semibold font-heading line-through">
                          $330.90
                        </span>
                      </p>
                    </div>
                  </div>
                </a>
                <a
                  className="inline-block bg-orange-300 hover:bg-orange-400 text-white font-bold font-heading py-4 px-8 rounded-md uppercase transition duration-200"
                  href='javascript:;'
                >
                  Buy Now
                </a>
              </div>
              <div className="w-full lg:w-1/3 px-3 mb-16 lg:mb-0">
                <a className="block mb-10" href='javascript:;'>
                  <div className="relative">
                    <span className="absolute bottom-0 left-0 ml-6 mb-6 px-2 py-1 text-xs font-bold font-heading bg-white border-2 border-red-500 rounded-full text-red-500">
                      -15%
                    </span>
                    <img
                      className="w-full h-96 object-cover"
                      src="yofte-assets/images/product-bottle.webp"
                      alt=""
                    />
                  </div>
                  <div className="mt-12">
                    <div className="mb-2">
                      <h3 className="mb-3 text-3xl text-white font-bold font-heading">
                        LIFE Bottle (2)
                      </h3>
                      <p className="text-xl font-bold font-heading text-white">
                        <span>$32.30</span>
                        <span className="text-xs text-gray-500 font-semibold font-heading line-through">
                          $49.90
                        </span>
                      </p>
                    </div>
                  </div>
                </a>
                <a
                  className="inline-block bg-orange-300 hover:bg-orange-400 text-white font-bold font-heading py-4 px-8 rounded-md uppercase transition duration-200"
                  href='javascript:;'
                >
                  Buy Now
                </a>
              </div>
              <div className="w-full lg:w-1/3 px-3">
                <a className="block mb-10" href='javascript:;'>
                  <div className="relative">
                    <span className="absolute bottom-0 left-0 ml-6 mb-6 px-2 py-1 text-xs font-bold font-heading bg-white border-2 border-red-500 rounded-full text-red-500">
                      -15%
                    </span>
                    <img
                      className="w-full h-96 object-cover"
                      src="yofte-assets/images/placeholder-surfing-blue.webp"
                      alt=""
                    />
                  </div>
                  <div className="mt-12">
                    <div className="mb-2">
                      <h3 className="mb-3 text-3xl text-white font-bold font-heading">
                        VONeon Board Surfing
                      </h3>
                      <p className="text-xl font-bold font-heading text-white">
                        <span>$295.30</span>
                        <span className="text-xs text-gray-500 font-semibold font-heading line-through">
                          $330.90
                        </span>
                      </p>
                    </div>
                  </div>
                </a>
                <a
                  className="inline-block bg-orange-300 hover:bg-orange-400 text-white font-bold font-heading py-4 px-8 rounded-md uppercase transition duration-200"
                  href='javascript:;'
                >
                  Buy Now
                </a>
              </div>
            </div>
          </div>
        </section> */}

        <section className="py-4 pt-5 bg-blue-300 footerStyle">
          <div className="container mx-auto px-2">
            <div className="flex flex-wrap -mx-4 pb-6 lg:pb-6 border-b border-gray-400">
              <div className="w-full lg:w-3/5 px-4 mb-6">
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-2 text-xl font-bold font-heading text-white">
                      Information
                    </h3>
                    <ul>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href="privacypolicy"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href="termsandconditions"
                        >
                          Terms And Condtions
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href="shippingandrefundpolicy"
                        >
                          Shipping And Refund Policy
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Investor Relations
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Reward program
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Delivery information
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Paying by invoice
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-2 text-xl font-bold font-heading text-white">
                      Customer Service
                    </h3>
                    <ul>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Return an order
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Search Terms
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Advanced Search
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Orders and Returns
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          FAQs
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Store Location
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-4">
                    <h3 className="mb-2 text-xl text-white font-bold font-heading">
                      Contact Us
                    </h3>
                    <ul>
                      <li className="mb-2">
                        <h4 className="mb-2 text-gray-50">Mobile</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          +91 95 517 89459
                        </a>
                      </li>
                      <li className="mb-2">
                        <h4 className="mb-2 text-gray-50">Email</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          contact@tamiltshirts.com
                        </a>
                      </li>
                      <li>
                        <h4 className="mb-2 text-gray-50">Live Chat</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          tamiltshirts
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-2/5 px-4 order-first lg:order-1 mb-2">
                <h3 className="mb-2 text-xl text-white font-bold font-heading">
                  Join our Newsletter
                </h3>
                <p className="mb-2 text-xl text-yellow-500 font-bold font-heading">
                  News, sales:
                </p>
                <div className="mb-2 relative lg:max-w-xl lg:mx-auto bg-white rounded-lg">
                  <div className="relative flex flex-wrap items-center justify-between">
                    <div className="relative flex-1">
                      {/* <span className="absolute top-0 left-0 ml-8 mt-4 font-semibold font-heading text-xs text-gray-400">
                      Drop your e-mail
                    </span> */}
                      <input
                        className="inline-block w-full px-8 placeholder-gray-900 border-0 focus:ring-transparent focus:outline-none rounded-md"
                        type="text"
                        placeholder="Drop your e-mail"
                      />
                    </div>
                    <a
                      className="inline-block w-auto bg-orange-300 hover:bg-orange-400 text-white font-bold font-heading py-2 px-8 rounded-md uppercase text-center"
                      href={() => {
                        return false;
                      }}
                    >
                      Join
                    </a>
                  </div>
                </div>
                <p className="text-gray-200">
                  The brown fox jumps over the lazy dog.
                </p>
              </div>
              <div className="w-full px-4 flex flex-wrap justify-between lg:order-last">
                <div className="w-full md:w-auto mb-4 md:mb-0 flex flex-wrap">
                  <img
                    className="mr-4 mb-2"
                    src="/yofte-assets/brands/visa.svg"
                    alt=""
                  />
                  <img
                    className="mr-4 mb-2"
                    src="/yofte-assets/brands/paypal.svg"
                    alt=""
                  />
                  <img
                    className="mb-2"
                    src="/yofte-assets/brands/mastercard.svg"
                    alt=""
                  />
                </div>
                <div className="w-full md:w-auto flex">
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/facebook-white-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/instagram-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/twitter-circle.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="Footer-content text-light">
              <h2 className="text-xl text-white font-bold font-heading">
                {" "}
                TAMIL T-SHIRTS THE NEW AGE ONLINE SHOPPING EXPERIENCE.
              </h2>
              <p className="text-gray-50 hover:text-gray-200">
                Tamiltshirts® is a lifestyle fashion brand committed to crafting
                innovative and distinctive fashion for the trendy, contemporary
                Indian. Our brand was born out of a dedication to making a
                positive impact through innovation, honesty, and thoughtfulness.
                We constantly strive to provide an altered and improved fashion
                experience that goes beyond the ordinary, ensuring that our
                creations resonate with your individuality and style. At
                Tamiltshirts®, we believe in setting new standards and pushing
                boundaries, offering you a fashion journey that is both
                meaningful and unique.
              </p>
              <br></br>
              <p className="text-gray-50 hover:text-gray-200">
                Empowering style since 2012, TAMIL T-SHIRTS® boasts a 400-member
                strong team, with a remarkable track record of 2 million
                products sold. Our unrestricted approach to experimentation
                allows us to strike the perfect balance between creativity and
                relatability, resulting in innovative designs that redefine
                fashion. Always at the forefront of style, our product range is
                consistently fresh and in-demand, with monthly sales exceeding 1
                lakh products. Operating on a vertical integration model, we
                manufacture our products in-house, eliminating middlemen to
                offer high-quality fashion at affordable prices. Our commitment
                extends beyond fashion to environmental and social
                responsibility, encompassing practices like rainwater
                harvesting, sustainable packaging, and employee benefits. At
                TAMIL T-SHIRTS®, we not only keep up with the latest trends but
                also set them. From our exclusive T-shirt collection to
                personalized Tamil letter T-shirts, we provide an accessible,
                affordable, and thoughtful online shopping experience tailored
                for the Indian fashion enthusiast.
              </p>{" "}
              <br></br>
              <h2 className="text-xl text-white font-bold font-heading">
                ONLINE SHOPPING AT TAMIL T-SHIRTS IS HASSLE-FREE, CONVENIENT AND
                SUPER-EXCITING!
              </h2>
              <p className="text-gray-50 hover:text-gray-200">
                Transforming the shopping experience, online shopping is now a
                delightful journey right from the comfort of your home. Gone are
                the days of planning trips to physical stores; today, you can
                indulge in the excitement of online shopping with
                tamiltshirt.com. Explore a world of amazing deals, discounts,
                and a user-friendly interface that stands out among other online
                shopping sites in India. With numerous shopping filters, we
                ensure your experience is truly hassle-free. Welcome to
                tamiltshirt.com, where convenience meets exceptional offers,
                making online shopping a joyous affair in every click.
              </p>{" "}
              <br></br>
              <p className="text-gray-50 hover:text-gray-200">
                Welcome to Tamiltshirts®, your ultimate destination for the
                trendiest online fashion. Explore our curated collection of
                fine, high-quality merchandise and embark on a delightful online
                shopping experience for both men and women. Discover a diverse
                range, from men's fashion to essential clothing items, as well
                as a wide variety of women's wear and accessories. Simply fill
                up your carts and enjoy swift home delivery for all orders.
                Indulge in the latest fashion trends with Tamiltshirts®, where
                style meets convenience. Our exciting categories, combined with
                exclusive online shopping offers, create an irresistible and
                uber cool combo for fashion enthusiasts. Whether you're shopping
                for yourself or looking for the perfect gift, Tamiltshirts®
                guarantees a smile on the faces of your near and dear ones.
                Elevate your fashion journey with Tamiltshirts®, where every
                click is a step towards style and satisfaction.
              </p>{" "}
              <br></br>
              <h2 className="text-xl text-white font-bold font-heading">
                TAMILTSHIRT.COM: THE QUIRKIEST ONLINE SHOPPING SITES OF ALL!
              </h2>
              <p className="text-gray-50 hover:text-gray-200">
                Experience the epitome of accessible online fashion with
                Tamiltshirt.com. Dive into our latest collections featuring
                Marvel t-shirts, including official Avengers and Captain America
                merchandise. We specialize in creating personalized Tamil word
                t-shirts and Tamil letter t-shirts, adding a unique touch to
                your wardrobe. Discover the quirkiest t-shirts unavailable on
                any other Indian online shopping site, exclusively showcased at
                Tamiltshirt.com. If your wardrobe craves a stylish
                transformation, Tamiltshirt.com stands as your top choice among
                online shopping sites. Explore our diverse range, from trendy
                sliders and joggers to cozy sweatshirts, fashionable bags, and
                backpacks. At Tamiltshirt.com, fashion doesn't have to break the
                bank. Say goodbye to expensive alternatives; here, you'll find
                affordable and chic options to elevate your style.
              </p>
              <p className="text-gray-50 hover:text-gray-200">
                What you wear is a reflection of your mood, and we've got the
                quirky t-shirts that allow you to express yourself with
                confidence. Take a journey through the latest runway trends on
                our Tamiltshirt.com blog and become a trendsetter among your
                peers. With us, you're not just shopping; you're defining your
                style story. No tags, just seamless style and unbeatable
                affordability, making Tamiltshirt.com your go-to destination for
                a fashion-forward online experience.
              </p>
              <br></br>
              <h2 className="text-xl text-white font-bold font-heading">
                DONT MISS OUT ON ACCESSORIES AVAILABLE ON OUR MULTI-FACETED
                ONLINE STORE!{" "}
              </h2>{" "}
              <p className="text-gray-50 hover:text-gray-200">
                Explore beyond the ordinary in online fashion with
                Tamiltshirt.com, where we don't just provide thrilling clothing
                options but also offer an array of fabulous accessories,
                featuring exceptionally cool bags and backpacks. Our collection
                is designed for those who appreciate compactness, hassle-free
                functionality, and the ease of storing essentials. Carry your
                belongings with an added touch of swag, thanks to our stylish
                designs.
              </p>{" "}
              <br></br>
              <p className="text-gray-50 hover:text-gray-200">
                At Tamiltshirt.com, we believe in ensuring that our accessories
                section is just as captivating as our clothing line. Immerse
                yourself in the world of cool designs that effortlessly
                complement your style. Our bags and backpacks are not just
                accessories; they are a statement. With Tamiltshirt.com, step
                into a realm where online fashion seamlessly combines
                practicality, style, and a touch of swag, ensuring you never
                compromise on individuality and convenience. <br></br>
                As for our accessories collection, they are also manufactured
                with impeccable quality materials. Our mobile covers are made
                from hard and durable polycarbonate, with a matte finish that
                will make for a great protection for your phone with the rough
                use that we put them through nowadays.{" "}
              </p>{" "}
              <br></br>
              <h2 className="text-xl text-white font-bold font-heading">
                TAMILTSHIRT.COM: THE UBER COOL ONLINE FASHION STORE FOR THE
                YOUTH
              </h2>
              <p className="text-gray-50 hover:text-gray-200">
                {" "}
                At tamiltshirt.com, we offer everything you need to elevate your
                cool quotient. Our extensive range includes plus-size clothing,
                casual shirts for men, and accessories for everyone. We bring
                forth a diverse array of choices, consolidating the best options
                that the online shopping platform in India has to offer under
                one umbrella. Explore a vast selection of men's t-shirts,
                joggers, sliders, Henley shirts, Polo t-shirts, Oxford pants,
                and more to curate a stunning ensemble. Our goal is to cater to
                all kinds of customers by understanding their needs and
                preferences. Communication is the cornerstone of our operation.
                Welcome to your Tamiltshirts® Online Fashion Shop! Don't miss
                out on our attractive daily online shopping offers. Express your
                style effortlessly with our wide range of apparels just a click
                away. Make a statement with our premium t-shirts online—more
                style, more value. Get more, pay less! Your fashion journey
                begins here.
              </p>{" "}
              <br></br>
              <h2 className="text-xl text-white font-bold font-heading">
                OUR PHILOSOPHY
              </h2>
              <p className="text-gray-50 hover:text-gray-200">
                At Tamiltshirts®, our mission is to craft fashion that not only
                aligns with the latest local and global trends but also provides
                functional value for your money. We prioritize quality
                materials, ensuring comfortable and flattering prints that make
                you stand out. We delve into the minds of our customers, drawing
                inspiration from their conversations and experiences to create
                graphics that resonate and relate. Constant and consistent
                innovation is at our core, offering our fans nothing short of
                the best at affordable rates. Unlike many, we don't outsource
                manufacturing; from design conception to production and styling,
                everything happens in-house. We're vertically integrated,
                bringing fashion directly from us to your doorstep without
                middlemen, ensuring reliability and building trust with our
                fans. Environmental impact is a concern, and we're actively
                implementing initiatives, such as optimizing processes to use
                only what we need from nature, rainwater harvesting, and
                recycling water from our RO facility. At Tamiltshirts®, our
                spirit is about making an impact by breaking conventions and
                offering a different perspective!
              </p>{" "}
              <br></br>
            </div>

            <div className="pt-15   flex items-center justify-center">
              <a
                className="inline-block mr-4 text-white text-2xl font-bold font-heading"
                href="/"
              >
                <img
                  className="h-7"
                  src={`/yofte-assets/logos/${lowercasenosp(
                    store
                  )}/logowhite.webp`}
                  alt=""
                  width="auto"
                />
              </a>
              <p className="inline-block text-sm text-gray-200">
                {footercopyrighttext}
              </p>
            </div>
            <br />
          </div>
        </section>
        {/* <section className="py-6 bg-blue-300">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap -mx-4 pb-6 lg:pb-16 border-b border-gray-400">
              <div className="w-full lg:w-3/5 px-4 mb-20">
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-8 text-xl font-bold font-heading text-white">
                      Information
                    </h3>
                    <ul>
                      <li className="mb-6">
                         <a
                          className="text-gray-50 hover:text-gray-200"
                          href="privacypolicy"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li className="mb-6">
                         <a
                          className="text-gray-50 hover:text-gray-200"
                           href="termsandconditions"
                        >
                          Terms And Condtions
                        </a>
                      </li>
                      <li className="mb-6">
                       <a
                          className="text-gray-50 hover:text-gray-200"
                          href="shippingandrefundpolicy"
                        >
                          Shipping And Refund Policy
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Investor Relations
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Reward program
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Delivery information
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Paying by invoice
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-8 text-xl font-bold font-heading text-white">
                      Customer Service
                    </h3>
                    <ul>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Return an order
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Search Terms
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Advanced Search
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Orders and Returns
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          FAQs
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Store Location
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-4">
                    <h3 className="mb-8 text-xl text-white font-bold font-heading">
                      Contact Us
                    </h3>
                    <ul>
                      <li className="mb-6">
                        <h4 className="mb-2 text-gray-50">Mobile</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          +91 95 517 89459
                        </a>
                      </li>
                      <li className="mb-6">
                        <h4 className="mb-2 text-gray-50">Email</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          contact@tamiltshirts.com
                        </a>
                      </li>
                      <li>
                        <h4 className="mb-2 text-gray-50">Live Chat</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          tamiltshirts
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-2/5 px-4 order-first lg:order-1 mb-20">
                <h3 className="mb-6 text-xl text-white font-bold font-heading">
                  Join our Newsletter
                </h3>
                <p className="mb-8 text-xl text-yellow-500 font-bold font-heading">
                  News, sales:
                </p>
                <div className="mb-6 relative lg:max-w-xl lg:mx-auto bg-white rounded-lg">
                  <div className="relative flex flex-wrap items-center justify-between">
                    <div className="relative flex-1">
                      <span className="absolute top-0 left-0 ml-8 mt-4 font-semibold font-heading text-xs text-gray-400">
                        Drop your e-mail
                      </span>
                      <input
                        className="inline-block w-full pt-8 pb-4 px-8 placeholder-gray-900 border-0 focus:ring-transparent focus:outline-none rounded-md"
                        type="text"
                        placeholder="contact@tamiltshirts.in"
                      />
                    </div>
                    <a
                      className="inline-block w-auto bg-orange-300 hover:bg-orange-400 text-white font-bold font-heading py-6 px-8 rounded-md uppercase text-center"
                      href={() => {
                        return false;
                      }}
                    >
                      Join
                    </a>
                  </div>
                </div>
                <p className="text-gray-200">
                  The brown fox jumps over the lazy dog.
                </p>
              </div>
              <div className="w-full px-4 flex flex-wrap justify-between lg:order-last">
                <div className="w-full md:w-auto mb-4 md:mb-0 flex flex-wrap">
                  <img
                    className="mr-4 mb-2"
                    src="yofte-assets/brands/visa.svg"
                    alt=""
                  />
                  <img
                    className="mr-4 mb-2"
                    src="yofte-assets/brands/paypal.svg"
                    alt=""
                  />
                  <img
                    className="mb-2"
                    src="yofte-assets/brands/mastercard.svg"
                    alt=""
                  />
                </div>
                <div className="w-full md:w-auto flex">
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="yofte-assets/buttons/facebook-white-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="yofte-assets/buttons/instagram-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img src="yofte-assets/buttons/twitter-circle.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="pt-8 flex items-center justify-center">
              <a
                className="inline-block mr-4 text-white text-2xl font-bold font-heading"
                href="/"
              >
                <img
                  className="h-7"
                  src={`yofte-assets/logos/${lowercasenosp(
                    store
                  )}/logowhite.webp`}
                  alt=""
                  width="auto"
                />
              </a>
              <p className="inline-block text-sm text-gray-200">
                © Copyright 2021 TamilTshirts
              </p>
            </div>
          </div>
        </section> */}
      </>
    </React.Fragment>
  );
}
