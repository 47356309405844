import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LazyImage from "react-lazy-blur-image";
import InfiniteScroll from "react-infinite-scroll-component";
import { PiCurrencyInr } from "react-icons/pi";
import { MdFavoriteBorder } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import {
  handleCartMinus,
  handleCartPlus,
  handleFetchCategoryData,
  handleFetchColorCodesData,
  handleFetchProductsColorCodesData,
  handleFetchProductsData,
  handleFetchProductsDataSliced,
  handleFetchProductsSizeData,
  handleFetchSizeData,
  handleFetchVersionManagerData,
  handleGetCartInfoStorageItems,
} from "../utilities/cartManager";
import "./customstyle.css";
import { getUserdata } from "../utilities/sessionexpiry";
import {
  handleGetCategoryInfoStorageItems,
  handleGetColorCodeStorageItems,
  handleGetProductsColorCodesStorageItems,
  handleGetProductsInfoStorageItems,
  handleGetProductsSizeStorageItems,
  handleGetSizeStorageItems,
  handleGetVersionManagerStorageItems,
  handleSetCategoryInfoStorageItems,
  handleSetVersionManagerStorageItems,
} from "../utilities/storageManager";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import MultiRangeSlider from "multi-range-slider-react";
import { useNavigate, useParams } from "react-router-dom";
import { AK } from "../constants/AppKeys";
import { callStores } from "../utilities/storeManager";
import { checkerArray, checkerString } from "../utilities/checker";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { lowercasenosp } from "../utilities/checker";
const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function PrivacyPolicy(props) {
  const {
    store,
    description,
    assets,
    storeid,
    hdimage,
    productimage,
    productviewimage,
    thumbnailviewimage,
    assetsUrl,
    categoryListData,
    userData,
    versionmanagerListData,
    colorcodesListData,
    productscolorcodesListData,
    sizeListData,
    productssizeListData,
    productsListData,
    cartinfoData,
    setCartInfoData,
    count,
    setCount,
    pageRefresh,
    footercopyrighttext,
    showFooter,
    setshowFooter,
  } = props;

  const [pageinit, setPageInit] = useState(false);
  const [showFormLoader, setFormLoader] = useState(false);
  const [producthasMore, setProductHasMore] = useState(true);
  const params = useParams();
  const [productsListPageData, setProductsListPageData] = useState([]);
  const [productsListDisplayData, setProductsListDisplayData] = useState([]);
  const [sizeListDisplayData, setSizeListDisplayData] = useState([]);
  const [colorListDisplayData, setColorListDisplayData] = useState([]);
  const [productsListDisplayDataCount, setProductsListDisplayDataCount] =
    useState(20);
  const [
    productsListIncreaseDisplayDataCount,
    setProductsListIncreaseDisplayDataCount,
  ] = useState(20);
  const [mobileFilterTab, setmobileFilterTab] = useState("sizes");

  if (!pageinit) {
    setPageInit(true);
    pageRefresh();
  }

  const getcartCount = () => {
    // return cartinfoData.cartcount;
    return cartinfoData.cartcount;
  };
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className="relative">
          <nav
            style={{ fontWeight: 500, fontSize: "13px" }}
            className="flex justify-between border-b navcustombg"
          >
            <div className="px-6 py-2 flex w-full items-center">
              {/* <a className="hidden xl:block mr-16" href='javascript:;'>
                <svg
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18 15.4688H0V17.7207H18V15.4688Z" fill="black" />
                  <path
                    d="M11.0226 7.87402H0V10.126H11.0226V7.87402Z"
                    fill="black"
                  />
                  <path d="M18 0.279297H0V2.53127H18V0.279297Z" fill="black" />
                </svg>
              </a> */}
              <ul className="hidden xl:flex font-heading">
                <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products/men">
                    MEN
                  </a>
                </li>
                <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products/women">
                    WOMEN
                  </a>
                </li>
                <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products/kids">
                    KIDS
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    className="hover:text-gray-600"
                    href="/products/couplecollection"
                  >
                    COUPLE COLLECTION
                  </a>
                </li>
                {/* <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products">
                    Apparels
                  </a>
                </li> */}
                {/* <li>
                  <a
                    className="hover:text-gray-600"
                    href="/products/politicalshirts"
                  >
                    POLITICAL SHIRTS
                  </a>
                </li> */}
                <li>
                  <a className="hover:text-gray-600" href="/products/apparels">
                    APPARELS
                  </a>
                </li>
              </ul>
              <a
                className="flex-shrink-0 xl:mx-auto text-3xl font-bold font-heading"
                href="/"
              >
                <img
                  style={{ height: "38px" }}
                  className="h-12"
                  src={`/yofte-assets/logos/${lowercasenosp(store)}/logo.webp`}
                  alt=""
                  width="auto"
                />
              </a>
              {/* <div className="hidden xl:inline-block mr-14">
                <input
                  className="py-5 px-8 w-full placeholder-gray-400 text-xs uppercase font-semibold font-heading bg-gray-50 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                  type="text"
                  placeholder="Search"
                />
              </div> */}

              <div className="hidden xl:flex items-center">
                {/* <div className="pull-right mainHeaderCols activemenuwrp">
                  <div className="actionMenu" style={{ padding: "15px" }}>
                    <span
                      className="actionMenu actionMenuInner"
                      id="testHeaderCart"
                    >
                      <a
                        href="/cart"
                        className="cartIcon"
                        style={{ paddingRight: 16, position: "relative" }}
                      >
                        <i className="icon_bag" aria-hidden="true" />
                        <span className="cartCount"> {getcartCount()}</span>
                      </a>
                    </span>
                    <span
                      className="actionMenu"
                      id="testHeadWish"
                      style={{ padding: "0px 5px", cursor: "pointer" }}
                    >
                      <div>
                        <div className="">
                          <i
                            className="icon_wishlist"
                            style={{ fontSize: "20px" }}
                          />
                        </div>
                      </div>
                    </span>
                    <a href="#" id="testHeaderAcc">
                      <i className="icon_user" />
                    </a>
                  </div>
                </div> */}

                <a className="mr-2 hover:text-gray-600" href="#">
                  <svg
                    width={23}
                    height={20}
                    viewBox="0 0 23 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4998 19.2061L2.70115 9.92527C1.92859 9.14433 1.41864 8.1374 1.24355 7.04712C1.06847 5.95684 1.23713 4.8385 1.72563 3.85053V3.85053C2.09464 3.10462 2.63366 2.45803 3.29828 1.96406C3.9629 1.47008 4.73408 1.14284 5.5483 1.00931C6.36252 0.875782 7.19647 0.939779 7.98144 1.19603C8.7664 1.45228 9.47991 1.89345 10.0632 2.48319L11.4998 3.93577L12.9364 2.48319C13.5197 1.89345 14.2332 1.45228 15.0182 1.19603C15.8031 0.939779 16.6371 0.875782 17.4513 1.00931C18.2655 1.14284 19.0367 1.47008 19.7013 1.96406C20.3659 2.45803 20.905 3.10462 21.274 3.85053V3.85053C21.7625 4.8385 21.9311 5.95684 21.756 7.04712C21.581 8.1374 21.071 9.14433 20.2984 9.92527L11.4998 19.2061Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <a
                  className="flex items-center hover:text-gray-600"
                  href="/cart"
                >
                  {/* <svg
                    className="mr-3"
                    width={23}
                    height={23}
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1159 8.72461H2.50427C1.99709 8.72461 1.58594 9.12704 1.58594 9.62346V21.3085C1.58594 21.8049 1.99709 22.2074 2.50427 22.2074H18.1159C18.6231 22.2074 19.0342 21.8049 19.0342 21.3085V9.62346C19.0342 9.12704 18.6231 8.72461 18.1159 8.72461Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.34473 6.34469V4.95676C6.34473 3.85246 6.76252 2.79338 7.5062 2.01252C8.24988 1.23165 9.25852 0.792969 10.3102 0.792969C11.362 0.792969 12.3706 1.23165 13.1143 2.01252C13.858 2.79338 14.2758 3.85246 14.2758 4.95676V6.34469"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span
                    style={{ padding: "3px" }}
                    className="inline-block w-6 h-6 text-center text-white bg-orange-300  hover:bg-orange-400 rounded-full font-heading"
                  >
                    {getcartCount()}
                  </span> */}
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/cart">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="iconoir:cart">
                        <g id="Group">
                          <path
                            id="Vector"
                            d="M19.5 22.2592C19.8978 22.2592 20.2794 22.1006 20.5607 21.8183C20.842 21.5359 21 21.153 21 20.7537C21 20.3544 20.842 19.9715 20.5607 19.6891C20.2794 19.4068 19.8978 19.2481 19.5 19.2481C19.1022 19.2481 18.7206 19.4068 18.4393 19.6891C18.158 19.9715 18 20.3544 18 20.7537C18 21.153 18.158 21.5359 18.4393 21.8183C18.7206 22.1006 19.1022 22.2592 19.5 22.2592ZM9.5 22.2592C9.89782 22.2592 10.2794 22.1006 10.5607 21.8183C10.842 21.5359 11 21.153 11 20.7537C11 20.3544 10.842 19.9715 10.5607 19.6891C10.2794 19.4068 9.89782 19.2481 9.5 19.2481C9.10218 19.2481 8.72064 19.4068 8.43934 19.6891C8.15804 19.9715 8 20.3544 8 20.7537C8 21.153 8.15804 21.5359 8.43934 21.8183C8.72064 22.1006 9.10218 22.2592 9.5 22.2592Z"
                            fill="#000000"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M5 4.19259H22L20 15.2333M5 4.19259L7 15.2333H20M5 4.19259C4.833 3.52312 4 2.18518 2 2.18518M20 15.2333H5.23C3.446 15.2333 2.5 16.0172 2.5 17.2407C2.5 18.4642 3.446 19.2481 5.23 19.2481H19.5"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </g>
                    </svg>
                  </a> */}
                  <div className="pull-right mainHeaderCols activemenuwrp">
                    <div className="actionMenu" style={{ padding: "10px" }}>
                      <span
                        className="actionMenu actionMenuInner"
                        id="testHeaderCart"
                      >
                        <a
                          href="/cart"
                          className="cartIcon"
                          style={{ paddingRight: 16, position: "relative" }}
                        >
                          {/* <i className="icon_bag" aria-hidden="true" /> */}
                          <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                          <span className="cartCount"> {getcartCount()}</span>
                        </a>
                      </span>

                      {/* <span
                        className="actionMenu actionMenuInner"
                        id="testHeaderCart"
                      >
                        <a
                          href="/cart"
                          className="cartIcon"
                          style={{ paddingRight: 16, position: "relative" }}
                        ></a>
                      </span> */}
                      {/* <span className="cartCount"> {getcartCount()}</span> */}

                      {/* <span className="cartCount"> {getcartCount()}</span> */}

                      {/* <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="iconoir:cart">
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M19.5 22.2592C19.8978 22.2592 20.2794 22.1006 20.5607 21.8183C20.842 21.5359 21 21.153 21 20.7537C21 20.3544 20.842 19.9715 20.5607 19.6891C20.2794 19.4068 19.8978 19.2481 19.5 19.2481C19.1022 19.2481 18.7206 19.4068 18.4393 19.6891C18.158 19.9715 18 20.3544 18 20.7537C18 21.153 18.158 21.5359 18.4393 21.8183C18.7206 22.1006 19.1022 22.2592 19.5 22.2592ZM9.5 22.2592C9.89782 22.2592 10.2794 22.1006 10.5607 21.8183C10.842 21.5359 11 21.153 11 20.7537C11 20.3544 10.842 19.9715 10.5607 19.6891C10.2794 19.4068 9.89782 19.2481 9.5 19.2481C9.10218 19.2481 8.72064 19.4068 8.43934 19.6891C8.15804 19.9715 8 20.3544 8 20.7537C8 21.153 8.15804 21.5359 8.43934 21.8183C8.72064 22.1006 9.10218 22.2592 9.5 22.2592Z"
                              fill="#000000"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              id="Vector_2"
                              d="M5 4.19259H22L20 15.2333M5 4.19259L7 15.2333H20M5 4.19259C4.833 3.52312 4 2.18518 2 2.18518M20 15.2333H5.23C3.446 15.2333 2.5 16.0172 2.5 17.2407C2.5 18.4642 3.446 19.2481 5.23 19.2481H19.5"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </g>
                      </svg> */}

                      {/* <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="iconoir:cart">
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M19.5 22.2592C19.8978 22.2592 20.2794 22.1006 20.5607 21.8183C20.842 21.5359 21 21.153 21 20.7537C21 20.3544 20.842 19.9715 20.5607 19.6891C20.2794 19.4068 19.8978 19.2481 19.5 19.2481C19.1022 19.2481 18.7206 19.4068 18.4393 19.6891C18.158 19.9715 18 20.3544 18 20.7537C18 21.153 18.158 21.5359 18.4393 21.8183C18.7206 22.1006 19.1022 22.2592 19.5 22.2592ZM9.5 22.2592C9.89782 22.2592 10.2794 22.1006 10.5607 21.8183C10.842 21.5359 11 21.153 11 20.7537C11 20.3544 10.842 19.9715 10.5607 19.6891C10.2794 19.4068 9.89782 19.2481 9.5 19.2481C9.10218 19.2481 8.72064 19.4068 8.43934 19.6891C8.15804 19.9715 8 20.3544 8 20.7537C8 21.153 8.15804 21.5359 8.43934 21.8183C8.72064 22.1006 9.10218 22.2592 9.5 22.2592Z"
                              fill="#000000"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              id="Vector_2"
                              d="M5 4.19259H22L20 15.2333M5 4.19259L7 15.2333H20M5 4.19259C4.833 3.52312 4 2.18518 2 2.18518M20 15.2333H5.23C3.446 15.2333 2.5 16.0172 2.5 17.2407C2.5 18.4642 3.446 19.2481 5.23 19.2481H19.5"
                              stroke="#000000"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </g>
                      </svg> */}

                      {/* <a
                        href="/cart"
                        className="cartIcon"
                        style={{ paddingRight: 16, position: "relative" }}
                      >
                        <i className="icon_bag" aria-hidden="true" />
                        <span className="cartCount"> {getcartCount()}</span>
                      </a> */}
                      {/* <span
                        className="actionMenu"
                        id="testHeadWish"
                        style={{ padding: "0px 5px", cursor: "pointer" }}
                      >
                        <div>
                          <div className="">
                            <i
                              className="icon_wishlist"
                              style={{ fontSize: "20px" }}
                            />
                          </div>
                        </div>
                      </span>
                      <a href="#" id="testHeaderAcc">
                        <i className="icon_user" />
                      </a> */}
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="hidden xl:flex items-center px-12 border-l font-heading hover:text-gray-600">
              <a
                href="/signin"
                style={{
                  display: userData != null ? "none" : "flex",
                }}
              >
                <svg
                  className="mr-3"
                  width={32}
                  height={31}
                  viewBox="0 0 32 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span style={{ width: "150px", paddingTop: "5px" }}>
                  SIGN&nbsp;IN
                </span>
              </a>

              <a
                className="dropdown"
                href={() => {
                  return false;
                }}
                style={{
                  display: userData === null ? "none" : "flex",
                  cursor: "pointer",
                }}
              >
                <svg
                  className="mr-3"
                  width={32}
                  height={31}
                  viewBox="0 0 32 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span style={{ width: "150px", paddingTop: "5px" }}>
                  {userData?.name}
                </span>
                <div className="dropdown-content">
                  <a href="/myprofile">My Profile</a>
                  <a href="/orderhistory">My Orders</a>
                  <a href="/changepassword">Change Password</a>
                  <hr
                    style={{
                      border: "1px solid #CFD5E2",
                      height: "2px",
                    }}
                  />
                  <a href="/signin">Logout</a>
                </div>
              </a>
            </div>
            {/* <div className="pull-right mainHeaderCols activemenuwrp">
              <div className="actionMenu" style={{ padding: "10px" }}>
                <span
                  className="actionMenu actionMenuInner"
                  id="testHeaderCart"
                >
                  <a
                    href="/cart"
                    className="cartIcon"
                    style={{ paddingRight: 16, position: "relative" }}
                  >
                    <i className="icon_bag" aria-hidden="true" />
                    <span className="cartCount"> {getcartCount()}</span>
                  </a>
                </span>
              </div>
            </div> */}

            <a
              className="xl:hidden flex mr-6 items-center text-gray-900"
              href="/cart"
            >
              {/* <svg
                className="mr-2"
                width={23}
                height={23}
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.1159 8.72461H2.50427C1.99709 8.72461 1.58594 9.12704 1.58594 9.62346V21.3085C1.58594 21.8049 1.99709 22.2074 2.50427 22.2074H18.1159C18.6231 22.2074 19.0342 21.8049 19.0342 21.3085V9.62346C19.0342 9.12704 18.6231 8.72461 18.1159 8.72461Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.34473 6.34469V4.95676C6.34473 3.85246 6.76252 2.79338 7.5062 2.01252C8.24988 1.23165 9.25852 0.792969 10.3102 0.792969C11.362 0.792969 12.3706 1.23165 13.1143 2.01252C13.858 2.79338 14.2758 3.85246 14.2758 4.95676V6.34469"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span
                style={{ padding: "3px" }}
                className="inline-block w-6 h-6 text-center text-white bg-orange-300  hover:bg-orange-400 rounded-full font-heading"
              >
                {getcartCount()}
              </span> */}
            </a>
            <a
              className="navbar-burger self-center mr-12 xl:hidden"
              href={() => {
                return false;
              }}
              onClick={() => {
                document.getElementById("mySidenav").style.width = "290px";
              }}
            >
              <svg
                width={20}
                height={12}
                viewBox="0 0 20 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 2H19C19.2652 2 19.5196 1.89464 19.7071 1.70711C19.8946 1.51957 20 1.26522 20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM19 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H19C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10ZM19 5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H19C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5Z"
                  fill="#8594A5"
                />
              </svg>
            </a>
            <div id="mySidenav" className="sidenav mr-12 xl:hidden">
              <a
                href={() => {
                  return false;
                }}
                className="closebtn"
                onClick={() => {
                  document.getElementById("mySidenav").style.width = "0";
                }}
              >
                &times;
              </a>
              <div className="xl:flex items-center border-l font-heading hover:text-gray-600">
                <a
                  href="/signin"
                  style={{
                    display: userData != null ? "none" : "flex",
                  }}
                >
                  <svg
                    className="mr-3"
                    width={32}
                    height={31}
                    viewBox="0 0 32 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span style={{ width: "150px", paddingTop: "5px" }}>
                    SIGN&nbsp;IN
                  </span>
                </a>

                <a
                  className="dropdown"
                  href="#"
                  style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    className="mr-3"
                    width={32}
                    height={31}
                    viewBox="0 0 32 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span style={{ width: "150px", paddingTop: "0px" }}>
                    {userData?.name}
                  </span>
                  <div className="dropdown-content-nav">
                    <a href="/myprofile">My Profile</a>
                    <a href="/orderhistory">My Orders</a>
                    <a href="/changepassword">Change Password</a>
                    <hr
                      style={{
                        border: "1px solid #CFD5E2",
                        height: "2px",
                      }}
                    />
                    <a href="/signin">Logout</a>
                  </div>
                </a>
              </div>
              <hr
                style={{
                  border: "1px solid #CFD5E2",
                  height: "2px",
                }}
              />
              <ul className="xl:flex" style={{ fontSize: "6px" }}>
                <li className="mr-12 ">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/men"
                  >
                    🕺 &nbsp;&nbsp; Men
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/women"
                  >
                    💃 &nbsp;&nbsp; Women
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/kids"
                  >
                    👶 &nbsp;&nbsp; Kids
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/couplecollection"
                  >
                    👩‍❤️‍👨 &nbsp;&nbsp; Couple Collection
                  </a>
                </li>
                {/* <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products">
                    Apparels
                  </a>
                </li> */}
                <li>
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/Apparels"
                  >
                    🚩 &nbsp;&nbsp; APPARELS
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </section>

        <section className="py-4 testing-1 bg-white container-y">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap -mx-3 mb-2">
              <div>
                <>
                  <br />
                  <h3>Privacy Policy</h3>
                  <br />
                  <p>
                    Your privacy is very important to us. We do not publish,
                    sell or rent your personal information to third parties for
                    their marketing purposes without your explicit consent.
                    Please read this privacy policy to learn more about the ways
                    in which we use and protect your personal information.
                  </p>
                  <br />
                  <p>
                    This privacy policy sets out how we use and protects any
                    information that you give us when you use this website. Our
                    Team is committed to ensuring that your privacy is
                    protected. Should we ask you to provide certain information
                    by which you can be identified when using this website, then
                    you can be assured that it will only be used in accordance
                    with this privacy statement. We may change this policy from
                    time to time by updating this page. You should check this
                    page from time to time to ensure that you are happy with any
                    changes.
                  </p>
                  <br />
                  <b>What we collect</b>
                  <br />
                  <br />
                  We may collect the following information: name contact
                  information including email address demographic information
                  such as postcode, preferences and interests other information
                  relevant to customer surveys and/or offers
                  <br />
                  <br />
                  <b>What we do with the information we gather</b>
                  <br />
                  <br />
                  We require this information to understand your needs and
                  provide you with a better service, and in particular for the
                  following reasons: Internal record keeping. We may use the
                  information to improve our products and services. We may
                  periodically send promotional emails about new products,
                  special offers or other information which we think you may
                  find interesting using the email address which you have
                  provided. From time to time, we may also use your information
                  to contact you for market research purposes. We may contact
                  you by email, phone, fax or mail. We may use the information
                  to customize the website according to your interests.
                  <br />
                  <br />
                  <b>Security</b>
                  <br />
                  <br />
                  <p>
                    We are committed to ensuring that your information is
                    secure. In order to prevent unauthorized access or
                    disclosure, we have put in place suitable physical,
                    electronic and managerial procedures to safeguard and secure
                    the information we collect online.
                  </p>
                  <br />
                  <b>How we use cookies</b>
                  <br />
                  <br />
                  <p>
                    A cookie is a small file which asks permission to be placed
                    on your computer's hard drive. Once you agree, the file is
                    added and the cookie helps analyse web traffic or lets you
                    know when you visit a particular site. Cookies allow web
                    applications to respond to you as an individual. The web
                    application can tailor its operations to your needs, likes
                    and dislikes by gathering and remembering information about
                    your preferences.
                  </p>
                  <br />
                  <p>
                    We use traffic log cookies to identify which pages are being
                    used. This helps us analyse data about web page traffic and
                    improve our website in order to tailor it to customer needs.
                    We only use this information for statistical analysis
                    purposes and then the data is removed from the system.
                  </p>
                  <br />
                  <p>
                    Overall, cookies help us provide you with a better website,
                    by enabling us to monitor which pages you find useful and
                    which you do not. A cookie in no way gives us access to your
                    computer or any information about you, other than the data
                    you choose to share with us. You can choose to accept or
                    decline cookies. Most web browsers automatically accept
                    cookies, but you can usually modify your browser setting to
                    decline cookies if you prefer. This may prevent you from
                    taking full advantage of the website.
                  </p>
                  <br />
                  <br />
                  <b>Links to other websites</b>
                  <br />
                  <br />
                  <p>
                    Our website may contain links to other websites of interest.
                    However, once you have used these links to leave our site,
                    you should note that we do not have any control over that
                    other website. Therefore, we cannot be responsible for the
                    protection and privacy of any information which you provide
                    whilst visiting such sites and such sites are not governed
                    by this privacy statement. You should exercise caution and
                    look at the privacy statement applicable to the website in
                    question.
                  </p>
                  <br />
                  <br />
                  <b>Controlling your personal information</b>
                  <br />
                  <br />
                  You may choose to restrict the collection or use of your
                  personal information in the following ways: whenever you are
                  asked to fill in a form on the website, look for the box that
                  you can click to indicate that you do not want the information
                  to be used by anybody for direct marketing purposes. If you
                  have previously agreed to us using your personal information
                  for direct marketing purposes, you may change your mind at any
                  time by writing to or emailing us at{" "}
                  <a
                    href="/cdn-cgi/l/email-protection"
                    className="__cf_email__"
                    data-cfemail="3a494f4a4a55484e7a5b5e435b48525b545e5359485b5c4e4914595557"
                  >
                    [email&nbsp;protected]
                  </a>
                  <p>
                    We will not sell, distribute or lease your personal
                    information to third parties unless we have your permission
                    or are required by law to do so. We may use your personal
                    information to send you promotional information about third
                    parties which we think you may find interesting if you tell
                    us that you wish this to happen.
                  </p>
                  <p>
                    You may request details of personal information which we
                    hold about you under the Data Protection Act 1998. A small
                    fee will be payable. If you would like a copy of the
                    information held on you please write to 230/1A NSK Street,
                    RV Nagar, Kodungaiyur, chennai - 118.
                  </p>
                  <p>
                    If you believe that any information we are holding on you is
                    incorrect or incomplete, please write to or email us as soon
                    as possible, at the above address. We will promptly correct
                    any information found to be incorrect.
                  </p>
                  We allow our users to unsubscribe from our mailing list.
                </>
              </div>
            </div>
          </div>
        </section>

        <section className="py-4 bg-blue-300 footerStyle">
          <div className="container mx-auto px-2">
            <div className="flex flex-wrap -mx-4 pb-6 lg:pb-6 border-b border-gray-400">
              <div className="w-full lg:w-3/5 px-4 mb-6">
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-2 text-xl font-bold font-heading text-white">
                      Information
                    </h3>
                    <ul>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href="privacypolicy"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href="termsandconditions"
                        >
                          Terms And Condtions
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href="shippingandrefundpolicy"
                        >
                          Shipping And Refund Policy
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Investor Relations
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Reward program
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Delivery information
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Paying by invoice
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-2 text-xl font-bold font-heading text-white">
                      Customer Service
                    </h3>
                    <ul>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Return an order
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Search Terms
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Advanced Search
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Orders and Returns
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          FAQs
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Store Location
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-4">
                    <h3 className="mb-2 text-xl text-white font-bold font-heading">
                      Contact Us
                    </h3>
                    <ul>
                      <li className="mb-2">
                        <h4 className="mb-2 text-gray-50">Mobile</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          +91 95 517 89459
                        </a>
                      </li>
                      <li className="mb-2">
                        <h4 className="mb-2 text-gray-50">Email</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          contact@tamiltshirts.com
                        </a>
                      </li>
                      <li>
                        <h4 className="mb-2 text-gray-50">Live Chat</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          tamiltshirts
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-2/5 px-4 order-first lg:order-1 mb-2">
                <h3 className="mb-2 text-xl text-white font-bold font-heading">
                  Join our Newsletter
                </h3>
                <p className="mb-2 text-xl text-yellow-500 font-bold font-heading">
                  News, sales:
                </p>
                <div className="mb-2 relative lg:max-w-xl lg:mx-auto bg-white rounded-lg">
                  <div className="relative flex flex-wrap items-center justify-between">
                    <div className="relative flex-1">
                      {/* <span className="absolute top-0 left-0 ml-8 mt-4 font-semibold font-heading text-xs text-gray-400">
                      Drop your e-mail
                    </span> */}
                      <input
                        className="inline-block w-full px-8 placeholder-gray-900 border-0 focus:ring-transparent focus:outline-none rounded-md"
                        type="text"
                        placeholder="Drop your e-mail"
                      />
                    </div>
                    <a
                      className="inline-block w-auto bg-orange-300 hover:bg-orange-400 text-white font-bold font-heading py-2 px-8 rounded-md uppercase text-center"
                      href={() => {
                        return false;
                      }}
                    >
                      Join
                    </a>
                  </div>
                </div>
                <p className="text-gray-200">
                  The brown fox jumps over the lazy dog.
                </p>
              </div>
              <div className="w-full px-4 flex flex-wrap justify-between lg:order-last">
                <div className="w-full md:w-auto mb-4 md:mb-0 flex flex-wrap">
                  <img
                    className="mr-4 mb-2"
                    src="/yofte-assets/brands/visa.svg"
                    alt=""
                  />
                  <img
                    className="mr-4 mb-2"
                    src="/yofte-assets/brands/paypal.svg"
                    alt=""
                  />
                  <img
                    className="mb-2"
                    src="/yofte-assets/brands/mastercard.svg"
                    alt=""
                  />
                </div>
                <div className="w-full md:w-auto flex">
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/facebook-white-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/instagram-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/twitter-circle.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="pt-15   flex items-center justify-center">
              <a
                className="inline-block mr-4 text-white text-2xl font-bold font-heading"
                href="/"
              >
                <img
                  className="h-7"
                  src={`/yofte-assets/logos/${lowercasenosp(
                    store
                  )}/logowhite.webp`}
                  alt=""
                  width="auto"
                />
              </a>
              <p className="inline-block text-sm text-gray-200">
                {footercopyrighttext}
              </p>
            </div>
            <br />
          </div>
        </section>

        {/* <section className="py-6 bg-blue-300">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap -mx-4 pb-6 lg:pb-16 border-b border-gray-400">
              <div className="w-full lg:w-3/5 px-4 mb-20">
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-8 text-xl font-bold font-heading text-white">
                      Information
                    </h3>
                    <ul>
                      <li className="mb-6">
                         <a
                          className="text-gray-50 hover:text-gray-200"
                          href="privacypolicy"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li className="mb-6">
                         <a
                          className="text-gray-50 hover:text-gray-200"
                           href="termsandconditions"
                        >
                          Terms And Condtions
                        </a>
                      </li>
                      <li className="mb-6">
                       <a
                          className="text-gray-50 hover:text-gray-200"
                          href="shippingandrefundpolicy"
                        >
                          Shipping And Refund Policy
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Investor Relations
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Reward program
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Delivery information
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Paying by invoice
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-8 text-xl font-bold font-heading text-white">
                      Customer Service
                    </h3>
                    <ul>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Return an order
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Search Terms
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Advanced Search
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Orders and Returns
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          FAQs
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Store Location
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-4">
                    <h3 className="mb-8 text-xl text-white font-bold font-heading">
                      Contact Us
                    </h3>
                    <ul>
                      <li className="mb-6">
                        <h4 className="mb-2 text-gray-50">Mobile</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          +91 95 517 89459
                        </a>
                      </li>
                      <li className="mb-6">
                        <h4 className="mb-2 text-gray-50">Email</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          hi@teemania.com
                        </a>
                      </li>
                      <li>
                        <h4 className="mb-2 text-gray-50">Live Chat</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          teemania
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-2/5 px-4 order-first lg:order-1 mb-20">
                <h3 className="mb-6 text-xl text-white font-bold font-heading">
                  Join our Newsletter
                </h3>
                <p className="mb-8 text-xl text-yellow-500 font-bold font-heading">
                  News, sales:
                </p>
                <div className="mb-6 relative lg:max-w-xl lg:mx-auto bg-white rounded-lg">
                  <div className="relative flex flex-wrap items-center justify-between">
                    <div className="relative flex-1">
                      <span className="absolute top-0 left-0 ml-8 mt-4 font-semibold font-heading text-xs text-gray-400">
                        Drop your e-mail
                      </span>
                      <input
                        className="inline-block w-full pt-8 pb-4 px-8 placeholder-gray-900 border-0 focus:ring-transparent focus:outline-none rounded-md"
                        type="text"
                        placeholder="print@teemania.in"
                      />
                    </div>
                    <a
                      className="inline-block w-auto bg-orange-300 hover:bg-orange-400 text-white font-bold font-heading py-6 px-8 rounded-md uppercase text-center"
                      href={() => {
                        return false;
                      }}
                    >
                      Join
                    </a>
                  </div>
                </div>
                <p className="text-gray-200">
                  The brown fox jumps over the lazy dog.
                </p>
              </div>
              <div className="w-full px-4 flex flex-wrap justify-between lg:order-last">
                <div className="w-full md:w-auto mb-4 md:mb-0 flex flex-wrap">
                  <img
                    className="mr-4 mb-2"
                    src="/yofte-assets/brands/visa.svg"
                    alt=""
                  />
                  <img
                    className="mr-4 mb-2"
                    src="/yofte-assets/brands/paypal.svg"
                    alt=""
                  />
                  <img
                    className="mb-2"
                    src="/yofte-assets/brands/mastercard.svg"
                    alt=""
                  />
                </div>
                <div className="w-full md:w-auto flex">
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/facebook-white-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/instagram-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img src="/yofte-assets/buttons/twitter-circle.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="pt-8 flex items-center justify-center">
              <a
                className="inline-block mr-4 text-white text-2xl font-bold font-heading"
                href="/"
              >
                <img
                  className="h-7"
                  src={`/yofte-assets/logos/${lowercasenosp(
                    store
                  )}/logowhite.webp`}
                  alt=""
                  width="auto"
                />
              </a>
              <p className="inline-block text-sm text-gray-200">
                © Copyright 2021 Teemania
              </p>
            </div>
          </div>
        </section> */}
      </>
    </React.Fragment>
  );
}
